import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class PaymentEscalatedService {
  //#region Private Fields
  private readonly _http: HttpClient;
  //#endregion

  constructor(http: HttpClient) {
    this._http = http;
  }

  sendPEEvent(triggerDays: number, uuid: string): Promise<boolean> {
    const url = `${environment.protections.ordersApi}protections/${uuid}/sendPaymentEscalated`;
    const body = {triggerDays: triggerDays};
    return new Promise<boolean>((resolve, reject) => {
      return this._http.post(
        url,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }
        ).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }
}
