import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IProtection } from '../../../core/models/protection.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { IProtectionsRequestFilter } from '../../../core/models/protectionsRequestFilter.interface';
import { SearchFilterService } from '../../../core/services/search-filter.service';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-protections-list',
  templateUrl: './protections-list.component.html',
  styleUrls: ['./protections-list.component.scss']
})
export class ProtectionsListComponent implements OnInit {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  private readonly _searchFilterService: SearchFilterService;
  //#endregion

  //#region Properties
  private _filter: IProtectionsRequestFilter = {
    declarationStates: 'TS_CHECK',
    offset: 0
  };
  public get filter(): IProtectionsRequestFilter {
    return this._filter;
  }

  private _protections: IProtection[];
  public get protections(): IProtection[] {
    return this._protections;
  }

  private _totalAmount: number;
  public get totalAmount(): number {
    return this._totalAmount;
  }

  private _currentPage: number;
  public get currentPage(): number {
    return this._currentPage;
  }

  private _isLoading = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _offset = 0;
  public get offset(): number {
    return this._offset;
  }

  private _usePlaymode = true;
  public get usePlaymode(): boolean {
    return this._usePlaymode;
  }
  public set usePlaymode(v: boolean) {
    sessionStorage.setItem('usePlaymode', `${v}`);
    this._usePlaymode = v;
  }
  //#endregion

  constructor(public translate: TranslateService, declarationService: DeclarationService, searchFilterService: SearchFilterService) {
    this._declarationService = declarationService;
    this._searchFilterService = searchFilterService;
  }

  ngOnInit() {
    if (this._searchFilterService.hasAutoFilter) {
      this._filter = this._searchFilterService.autoFilter;
    }
    if (!this._filter.searchEmployeeUuid) {
      this._filter.searchEmployeeUuid = '-1';
    }
    this.loadPage(1);
  }

  async loadPage(page: number) {
    try {
      this._isLoading = true;
      this._filter.offset = (page - 1) * 30;
      this._offset = (page - 1) * 30;
      this._protections = [];
      this._totalAmount = 0;
      const searchFilter = Object.assign({}, this._filter);
      if (searchFilter.searchEmployeeUuid === '-1') {
        delete searchFilter.searchEmployeeUuid;
      }

      if (searchFilter.searchEmployeeUuid === 'no_editor') {
        delete searchFilter.searchEmployeeUuid;
        searchFilter.unassigned = true;
      }
      const list = await this._declarationService.getProtections(searchFilter);
      this._totalAmount = list.totalAmount;
      this._protections = list.declarations;
      this._currentPage = page;
      delete this._filter.offset;
      this._isLoading = false;
    } catch (e) {
      this._isLoading = false;
    }
  }

  setFilters(filter: IProtectionsRequestFilter): void {
    this._searchFilterService.setAutoFilter(filter);
    this._filter = filter;
    this.loadPage(1);
  }

  showButton(event) {
    event.target.classList.add('hover');
  }

  hideButton(event) {
    event.target.classList.remove('hover');
  }

  async downloadCSV() {
    const searchFilter = Object.assign({}, this._filter);
    delete searchFilter.searchEmployeeUuid;
    delete searchFilter.offset;

    const csv = await this._declarationService.getRiskCSV(searchFilter);
    saveAs(csv, `risk_${formatDate(Date.now(), 'yyMMdd-HH:mm:ss', 'en')}.csv`);
  }
}
