import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { ProtectionsRoutingModule } from './configuration/protections-routing.module';
import { FilterFormComponent } from './views/filter-form/filter-form.component';
import { ProtectionsListComponent } from './views/protections-list/protections-list.component';
import { NumberFormatPipe } from '../../../core/pipes/number-format.pipe';
import { WebAddressPipe } from '../../../core/pipes/web-address.pipe';
import { ProtectionDetailsComponent } from './views/protection-details/protection-details.component';
import { BuyerInfoComponent } from './views/info-cards/buyer-info/buyer-info.component';
import { ProtectionHistoryComponent } from './views/protection-history/protection-history.component';
import { ProtectionsInfoComponent } from './views/info-cards/protections-info/protections-info.component';
import { DecisionInfoComponent } from './views/info-cards/decision-info/decision-info.component';
import { PaginationComponent } from './views/pagination/pagination.component';
import { DynamicDatePipe } from '../../../core/pipes/dynamic-date.pipe';
import { DynamicCurrencyPipe } from '../../../core/pipes/dynamic-currency.pipe';
import { DynamicDecimalPipe } from '../../../core/pipes/dynamic-decimal.pipe';
import { Nl2BrPipe } from '../../../core/pipes/nl2br.pipe';
import { DeclarationMessageComponent } from './views/declaration-message/declaration-message.component';
import { CommentsInfoComponent } from './views/info-cards/comments-info/comments-info.component';
import { DocumentModalComponent } from './views/document-modal/document-modal.component';
import { DeclarationsDocumentUploadComponent } from './views/declarations-document-upload/declarations-document-upload.component';
import { DeclarationsDocumentComponent } from './views/declarations-document/declarations-document.component';
import { BankAccountDetailsComponent } from './views/bank-account-details/bank-account-details.component';
import { BankAccountFormComponent } from './views/bank-account-form/bank-account-form.component';
import { FormBuilder } from '@angular/forms';
import { CloseBoxComponent } from './views/close-box/close-box.component';
import { HtmlEncodePipe } from '../../../core/pipes/html-encode.pipe';
import { ErrorModalComponent } from './views/error-modal/error-modal.component';
import { CommentStreamItemComponent } from './comment-stream-item/comment-stream-item.component';
import { PaymentEscalatedUIModule } from '../../payment-escalated/ui/payment-escalated-ui.module';

@NgModule({
  imports: [
    SharedModule,
    ProtectionsRoutingModule,
    PaymentEscalatedUIModule
  ],
  declarations: [
    FilterFormComponent,
    ProtectionsListComponent,
    NumberFormatPipe,
    WebAddressPipe,
    ProtectionDetailsComponent,
    BuyerInfoComponent,
    DecisionInfoComponent,
    ProtectionHistoryComponent,
    ProtectionsInfoComponent,
    PaginationComponent,
    DynamicDatePipe,
    DynamicCurrencyPipe,
    DynamicDecimalPipe,
    Nl2BrPipe,
    HtmlEncodePipe,
    DeclarationMessageComponent,
    CommentsInfoComponent,
    DocumentModalComponent,
    DeclarationsDocumentUploadComponent,
    DeclarationsDocumentComponent,
    BankAccountDetailsComponent,
    BankAccountFormComponent,
    CloseBoxComponent,
    ErrorModalComponent,
    CommentStreamItemComponent
  ],
  providers: [FormBuilder]
})
export class ProtectionsUIModule { }
