import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IEmployeesList } from '../../features/protections/core/models/employees.interface';

@Injectable()
export class UserService {
  private readonly _http: HttpClient;

  //#region Properties
  private _firstName = new ReplaySubject<string>();
  public get firstName(): ReplaySubject<string> {
    return this._firstName;
  }

  private _lastName = new ReplaySubject<string>();
  public get lastName(): ReplaySubject<string> {
    return this._lastName;
  }

  private _locale = new ReplaySubject<string>();
  public get locale(): ReplaySubject<string> {
    return this._locale;
  }

  private _email = new BehaviorSubject<string>('');
  public get email(): BehaviorSubject<string> {
    return this._email;
  }

  private _employee_uuid = new BehaviorSubject<string>('');
  public get employee_uuid(): BehaviorSubject<string> {
    return this._employee_uuid;
  }

  private _employee_roles = new BehaviorSubject<string[]>([]);
  public get employee_roles(): BehaviorSubject<string[]> {
    return this._employee_roles;
  }

  private _employees = new BehaviorSubject<IEmployeesList>({
    serviceCore: [],
    serviceTemp: [],
    serviceMgr: []
  });
  public get employees(): BehaviorSubject<IEmployeesList> {
    return this._employees;
  }
  //#endregion

  constructor(http: HttpClient) {
    this._http = http;
    this.init();
  }

  async init() {
    this._email.subscribe(async (next) => {
      if (next !== '') {
        const url = `${environment.protections.declarationAPI}employees`;
        try {
          const employeesList = await new Promise<IEmployeesList>((resolve, reject) => {
            return this._http.get(url).subscribe(res => {
                resolve(res as IEmployeesList);
              },
              err => {
                reject(err);
              });
          });
          this._employees.next(employeesList);
          let uuid = '';
          if (employeesList.serviceMgr.length > 0) {
            for (const iEmployee of employeesList.serviceMgr) {
              if (iEmployee.email === next) {
                uuid = iEmployee.uuid;
                break;
              }
            }
          }
          if (uuid === '' && employeesList.serviceTemp.length > 0) {
            for (const iEmployee of employeesList.serviceTemp) {
              if (iEmployee.email === next) {
                uuid = iEmployee.uuid;
                break;
              }
            }
          }
          if (uuid === '' && employeesList.serviceTemp.length > 0) {
            for (const iEmployee of employeesList.serviceCore) {
              if (iEmployee.email === next) {
                uuid = iEmployee.uuid;
                break;
              }
            }
          }
          if (uuid !== '') {
            this._employee_uuid.next(uuid);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
}
