import { Veto } from './veto';
import { Review, ReviewState } from '../../schemas/review';
import { Injectable } from '@angular/core';

@Injectable()

export class DiscardMutation extends Veto {
  public async apply(review: Review): Promise<ReviewState> {
    if (![ReviewState.REJECTED, ReviewState.SUBMITTED].includes(review.state)) {
      throw new Error('Only review state REJECTED or SUBMITTED can be DISCARDED');
    }

    return await this.changeReviewState(review.id, ReviewState.DISCARDED);
  }
}
