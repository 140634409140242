import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProtectionDetailsComponent } from '../views/protection-details/protection-details.component';
import { FullLayoutComponent } from '../../../../core/page-frame/full-layout';
import { ProtectionsListComponent } from '../views/protections-list/protections-list.component';
import {ReviewRejectionComponent} from '../../../review-rejection/review-rejection.component';


const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        component: ProtectionsListComponent
      },
      {
        path: 'review-rejection',
        component: ReviewRejectionComponent
      },
      { path: 'protection/:id', component: ProtectionDetailsComponent },
      { path: 'protection/:id/:offset', component: ProtectionDetailsComponent },
      { path: 'protection/:id/:declaration/:offset', component: ProtectionDetailsComponent },
      {
        path: '**',
        component: ProtectionsListComponent
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class ProtectionsRoutingModule { }
