import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ModalModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { LoadingIndicatorComponent } from '../controls/loading-indicator/loading-indicator.component';
import { LoadingOverlayComponent } from '../controls/loading-overlay/loading-overlay.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoadingIndicatorComponent,
    LoadingOverlayComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    // NgxMyDatePickerModule,
    TooltipModule,
    ModalModule,
    TabsModule,
    LoadingIndicatorComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
    AngularMyDatePickerModule
  ]
})
export class SharedModule { }
