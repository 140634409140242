import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'webAddress'
})
export class WebAddressPipe implements PipeTransform {

  transform(value: string): string {
    if (value.startsWith('http://') || value.startsWith('https://')) {
      return value;
    }

    return 'http://' + value;
  }

}
