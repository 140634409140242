import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeclarationService } from '../../../core/services/declaration.service';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-declaration-document-upload',
  styleUrls: ['./declarations-document-upload.component.scss'],
  templateUrl: './declarations-document-upload.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DeclarationsDocumentUploadComponent implements OnInit {
  //#region Properties
  @ViewChild('documentInput') private _documentInput: ElementRef;
  private _files: Array<File> = [];
  private _dragCounter = 0;

  @Output()
  public documentsChanged = new EventEmitter<IDocumentDisplayData[]>();

  private _disableUpload = false;
  @Input()
  public get disableUpload(): boolean {
    return this._disableUpload;
  }
  public set disableUpload(v: boolean) {
    this._disableUpload = v;
  }

  private _assign = new EventEmitter<void>();
  @Output() public get assign(): EventEmitter<void> {
    return this._assign;
  }

  private _disableDelete = false;
  @Input()
  public get disableDelete(): boolean {
    return this._disableDelete;
  }
  public set disableDelete(v: boolean) {
    this._disableDelete = v;
  }

  private _documents: IDocumentDisplayData[] = [];
  @Input()
  public get documents(): IDocumentDisplayData[] {
    return this._documents;
  }

  private _declarationUuid: string;
  @Input()
  public get declarationUuid(): string {
    return this._declarationUuid;
  }
  public set declarationUuid(v: string) {
    this._declarationUuid = v;
  }

  @Input() type: string;

  private _highlight = false;
  public get highlight(): boolean {
    return this._highlight;
  }
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _declarationService: DeclarationService
  ) {

  }
  //#endregion
   //#region Public Methods
  async ngOnInit() {
    const docs = await this._declarationService.getDocuments(this._declarationUuid);
    this._documents = docs.map(document => {
      return {document};
    });
  }

  public writeValue(value: any): void {
    if (value !== this._documents) {
      this._documents = value;
      this.documentsChanged.emit(null);
    }
  }

  public onFilesAdded() {
    this._upload(this._documentInput.nativeElement.files);
  }

  public dropFiles(e) {
    e.preventDefault();
    this._highlight = false;
    this._upload(e.dataTransfer.files);
  }

  public dragOverHandler(e) {
    e.preventDefault();
  }

  public highlightDropBox() {
    this._dragCounter ++;
    this._highlight = true;
  }

  public unHighlightDropBox() {
    this._dragCounter --;
    if (this._dragCounter === 0) {
      this._highlight = false;
    }
  }

  private async _upload(files: { [key: string]: File }) {
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this._files.push(files[key]);
      }
    }

    try {
      const unsubscribe = new Subject<void>();
      this._declarationService.assignee.pipe(takeUntil(unsubscribe)).subscribe(
        async (assignee: string) => {
          if (assignee === '') {
            this._assign.emit();
          } else {
            unsubscribe.next();
            unsubscribe.complete();
            const documentsToAdd = await this._declarationService.upload(this._declarationUuid, this._files);
            this._documents = this._documents.concat(documentsToAdd);
            this._files = [];
            this.documentsChanged.emit(this._documents);
            forkJoin(documentsToAdd.map(x => x.progress))
              .map(results => results.reduce((x, y) =>  x + y))
              .subscribe(sumOfAllProgressValues => {
                if (sumOfAllProgressValues === documentsToAdd.length * 100) {
                  this._documentInput.nativeElement.value = '';
                }
              });
          }
        }
      );
    } catch (e) { }
  }

  public async onDeleteDocument(documentViewModel: IDocumentDisplayData): Promise<void> {
    if (!documentViewModel.document.uuid ||
      await this._declarationService.deleteDocument(this._declarationUuid, documentViewModel.document.uuid)) {
      const index = this._documents.findIndex(x => x === documentViewModel);
      if (index > -1) {
        this._documents.splice(index, 1);
        this.documentsChanged.emit(this._documents);
      }
    }
  }
  //#endregion
}
