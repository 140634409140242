import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { PaymentEscalatedUIModule } from '../ui/payment-escalated-ui.module';
import { PaymentEscalatedService } from './services/payment-escalated.service';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [],
  exports: [
    PaymentEscalatedUIModule
  ],
  providers: [
    PaymentEscalatedService
  ]
})
export class PaymentEscalatedModule { }
