import { Component, Input } from '@angular/core';
import { Review } from '../schemas/review';
import { ReviewSearchOptions } from '../schemas/reviewSearchOptions';
import { ReviewsDomUtilsService } from '../services/reviews/reviews.dom-utils.service';
import { Veto } from '../services/veto/veto';
import { ApproveSubmittedReviewsResponse } from '../schemas/api';
import { ReviewSearchFormService } from '../services/shared/review-search-form.service';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss']
})
export class ReviewListComponent {
  private _reviews: Review[] = [];
  @Input() searchValue: string;
  @Input() shopReference: string;
  @Input() searchBy: ReviewSearchOptions;

  reviewSearchOptions = ReviewSearchOptions;

  @Input() public set reviews(reviews) {
    this._reviews = reviews;
  }

  constructor(
    private reviewsDomUtilsService: ReviewsDomUtilsService,
    private vetoService: Veto,
    private reviewSearchFormService: ReviewSearchFormService
  ) {}

  public get reviews(): Review[] {
    return this._reviews;
  }

  onReviewSelectionButtonClick(setToChecked: boolean): void {
    this.reviewsDomUtilsService.toggleAllReviewCheckboxes(setToChecked);
    this.reviewsDomUtilsService.updateReviewsActionButtons();
  }

  async approveAllSelectedReviews(): Promise<void> {
    const selectedReviewIds = this.reviewsDomUtilsService.getSelectedReviewIds();
    const confirmationMessage = `Are you sure you want to approve all ${selectedReviewIds.length} selected reviews? This cannot be undone.`;
    const confirmation = window.confirm(confirmationMessage);
    if (confirmation) {
      const failedReviewIdsResponse: ApproveSubmittedReviewsResponse =
        await this.vetoService.approveSubmittedReviews(this.shopReference, selectedReviewIds);

      const failedReviewIds = failedReviewIdsResponse ? failedReviewIdsResponse.errors : [];
      const successfulReviewIds = selectedReviewIds.filter(id => !failedReviewIds.includes(id));

      this.reviewSearchFormService.triggerSearch();
      this.reviewsDomUtilsService.setDefaultReviewActionButtonsDisabledState();
      this.showBulkApprovalResultMessage(successfulReviewIds, failedReviewIds);
    }
  }

  showBulkApprovalResultMessage(successfulReviewIds: string[], failedReviewIds: string[]): void {
    if (failedReviewIds.length === 0) {
      return;
    }
    const successfulReviewIdsMessage = successfulReviewIds.length > 0
      ? `Successfully approved ${successfulReviewIds.length} reviews.`
      : '';
    const failedReviewIdsMessage = failedReviewIds.length > 0
      ? `Failed to approve ${failedReviewIds.length} reviews.`
      : '';
    const message = `${successfulReviewIdsMessage} ${failedReviewIdsMessage}`;
    alert(message);
  }
}
