import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { IProtectionHistoryItem } from '../../../core/models/protectionHistoryItem.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-protection-history',
  templateUrl: './protection-history.component.html',
  styleUrls: ['./protection-history.component.scss'],
  animations: [
    trigger('closed', [
      state('true', style({
        height: 0,
        padding: 0
      })),
      state('false', style({
        height: '*',
        padding: '1.25rem'
      })),
      transition('true <=> false', animate('0.4s ease-in-out'))
    ])
  ]
})
export class ProtectionHistoryComponent implements OnInit {
  //#region Properties

  private _actionsList: IProtectionHistoryItem[];
  public get actionsList(): IProtectionHistoryItem[] {
    return this._actionsList;
  }
  @Input() public set actionsList(v: IProtectionHistoryItem[]) {
    this._actionsList = v;
  }

  private _scrolled: EventEmitter<void>;
  @Input() public set scrolled(v: EventEmitter<void>) {
    this._scrolled = v;
  }

  private _orderedAsc = false;
  public get orderedAsc(): boolean {
    return this._orderedAsc;
  }

  private _closed = true;
  public get closed(): boolean {
    return this._closed;
  }
  //#endregion
  ngOnInit() {
    this._scrolled.subscribe(() => this._closed = false);
  }

  orderList() {
    if (this._orderedAsc) {
      this._actionsList.sort((a, b) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
      });
    } else {
      this._actionsList.sort((a, b) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateA - dateB;
      });
    }

    this._orderedAsc = !this._orderedAsc;
  }

  toggleShow() {
    this._closed = !this._closed;
  }
}
