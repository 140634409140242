import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IDeclaration } from '../models/declaration.interface';
import { IDeclarationMessage } from '../models/declarationMessage.interface';
import { IProtectionHistoryItem } from '../models/protectionHistoryItem.interface';
import { IDecision } from '../models/decision.interface';
import { IProtectionsRequest } from '../models/protectionsRequest.interface';
import { IProtectionInfo } from '../models/protectionInfo.interface';
import { ITimer } from '../models/timer.interface';
import { IDocument } from '../models/document.interface';
import { IDocumentDisplayData } from '../models/document-display-data.interface';
import { fileTypes } from '../../ui/configuration/fileTypes';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IControlCommitButton } from '../models/control-commit-button.interface';
import { IBankAccountData } from '../models/bankAccountData.interface';

@Injectable()
export class DeclarationService {
  //#region Private Fields
  private readonly _http: HttpClient;
  //#endregion

  controlCommitButton: BehaviorSubject<IControlCommitButton> = new BehaviorSubject<IControlCommitButton>({
    buttonShownForShop: false,
    buttonShownForBuyer: false,
    attachToShopDocumentMessage: [],
    attachToConsumerDocumentMessage: [],
    assignedToOtherUser: '',
    commitTypeShop: '',
    commitTypeBuyer: ''
  });

  assignee: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(http: HttpClient) {
    this._http = http;
  }

  getDeclaration(declarationUuid: string): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}`).subscribe(res => {
          if ((res as IDeclaration).assignee) {
            this.assignee.next((res as IDeclaration).assignee.uuid);
          } else {
            this.assignee.next('');
          }
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  declarationCommitCompleteness(declarationUuid: string, allowToConsumer: boolean, allowToShop: boolean): Promise<boolean> {
    const body = {
      allowToConsumer,
      allowToShop
    };
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<boolean>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/commit-completeness`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }
      ).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }

  declarationCreateUpdateDecision(declarationUuid: string, decision: IDecision): Promise<boolean> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<boolean>((resolve, reject) => {
      return this._http.put(`${url}${declarationUuid}/decision`,
        JSON.stringify(decision),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }


  declarationCommitDecision(declarationUuid: string): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/commit-decision`,
        null
      ).subscribe(res => {
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  claimDeclaration(declarationUuid: string): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/assign`,
        null
      ).subscribe(res => {
          if ((res as IDeclaration).assignee) {
            this.assignee.next((res as IDeclaration).assignee.uuid);
          }
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  closeDeclaration(declarationUuid: string): Promise<boolean> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<boolean>((resolve, reject) => {
      return this._http.put(
        `${url}${declarationUuid}/close-declaration`,
        JSON.stringify({})).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }

  getProtectionHistory(protectionId: string): Promise<IProtectionHistoryItem[]> {
    const url = `${environment.protections.declarationAPI}protections/`;
    return new Promise<IProtectionHistoryItem[]>((resolve, reject) => {
      return this._http.get(`${url}${protectionId}/history`).subscribe(res => {
          resolve(res as IProtectionHistoryItem[]);
        },
        err => {
          reject(err);
        });
    });
  }

  getMessagesForDeclaration(declarationUuid: string): Promise<IDeclarationMessage[]> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclarationMessage[]>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}/messages`).subscribe(res => {
          resolve(res as IDeclarationMessage[]);
        },
        err => {
          reject(err);
        });
    });
  }

  getMessagesByMessageId(declarationUuid: string, messageUuid: string): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclarationMessage>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}/messages/${messageUuid}`).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  createMessageForDeclaration(declarationUuid: string, message: IDeclarationMessage): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclarationMessage>((resolve, reject) => {
      return this._http.post(`${url}${declarationUuid}/messages`,
        JSON.stringify(message),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  commitMessage(declarationUuid: string, messageUuid: string): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclarationMessage>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/messages/${messageUuid}/commit`,
        null
        ).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  attachDocumentToMessage(declarationUuid: string,
    messageUuid: string,
    documentUuids: string[],
    action: string): Promise<IDeclarationMessage> {

    const url = `${environment.protections.declarationAPI}declarations/`;

    return new Promise<IDeclarationMessage>((resolve, reject) => {
      const body = {
        documentUuids: documentUuids,
        action: action
      };

      return this._http.patch(
        `${url}${declarationUuid}/messages/${messageUuid}`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  endTimer(declarationUuid: string, timer: ITimer): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.put(`${url}${declarationUuid}/timers`,
        JSON.stringify(timer),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  getProtections(filters?: object): Promise<IProtectionsRequest> {
    let url = `${environment.protections.ordersApi}protections`;
    const params = new Array<string>();

    if (filters) {
      for (const filter of Object.keys(filters)) {
        params.push(filter + '=' + encodeURI(filters[filter]));
      }

      url = `${url}?${params.join('&')}`.replace(/\+/g, '%2B');
    }

    return new Promise<IProtectionsRequest>((resolve, reject) => {
      return this._http.get(url).subscribe(res => {
          resolve(res as IProtectionsRequest);
        },
        err => {
          reject(err);
        });
    });
  }

  getProtectionInfo(uuid: string): Promise<IProtectionInfo> {
    const url = `${environment.protections.ordersApi}protections`;

    return new Promise<IProtectionInfo>((resolve, reject) => {
      return this._http.get(`${url}/${uuid}`).subscribe(res => {
          resolve(res as IProtectionInfo);
        },
        err => {
          reject(err);
        });
    });
  }

  public async upload(declarationUuid: string, files: Array<File>): Promise<Array<IDocumentDisplayData>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}declarations/${declarationUuid}/documents`;
    const status: Array<IDocumentDisplayData> = [];

    await this._asyncForEach(files, async (file) => {
      const data = {} as IDocumentDisplayData;

      if (!fileTypes.includes(file.type)) {
        data.error = 'WRONG_TYPE';
        data.document = {fileName: file.name} as IDocument;
      } else if (file.size > 10000000) {
        data.error = 'FILE_SIZE';
        data.document = {fileName: file.name} as IDocument;
      } else {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        const request = new HttpRequest('POST', url, formData, {reportProgress: true});
        const progress = new BehaviorSubject<number>(0);

        this._http.request(request).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percent = Math.round(100 * event.loaded / event.total);
            progress.next(percent);
          } else if (event instanceof HttpResponse) {
            data.document = event.body as IDocument;
            progress.complete();
          }
        }, error => {
          if (error instanceof HttpErrorResponse
            && error.error
            && error.error.message.indexOf('FileSizeLimitExceededException') > -1) {
            data.error = 'FILE_SIZE';
          } else if (error instanceof HttpErrorResponse && error.status === 400) {
            data.error = 'WRONG_TYPE';
          } else {
            data.error = 'GENERAL_ERROR';
          }

          data.document = {fileName: file.name} as IDocument;
          progress.complete();
        });

        data.progress = progress.asObservable();
        await new Promise(resolve => setTimeout(resolve, 100));
      }


      status.push(data);
    });

    return status;
  }

  public getDocuments(declarationUuid: string): Promise<Array<IDocument>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}declarations/${declarationUuid}/documents`;

    return new Promise<Array<IDocument>>(((resolve, reject) => {
      this._http.get(url).subscribe(res => {
          resolve(res as Array<IDocument>);
        },
        error => {
          reject(error);
        });
    }));
  }

  public getDocument(declarationUuid: string, documentUuid: string): Promise<IDocument> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}declarations/${declarationUuid}/documents/${documentUuid}`;

    return new Promise<IDocument>(((resolve, reject) => {
      this._http.get(url).subscribe(res => {
          resolve(res as IDocument);
        },
        error => {
          reject(error);
        });
    }));
  }

  public getDocumentBlob(declarationUuid: string, documentUuid: string): Promise<Blob> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}declarations/${declarationUuid}/documents/${documentUuid}/download`;

    return new Promise<Blob>(
      (resolve, reject) => {
        this._http.get(url, { responseType: 'blob' })
          .subscribe(
            x => resolve(x),
            err => reject(err)
          );
      }
    );
  }

  public deleteDocument(declarationUuid: string, documentUuid: string): Promise<boolean> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}declarations/${declarationUuid}/documents/${documentUuid}`;

    return new Promise<boolean>(((resolve, reject) => {
      this._http.delete(url).subscribe(res => {
          resolve(true);
        },
        error => {
          reject(error);
        });
    }));
  }

  public getBankAccount(declarationUuid: string): Promise<IBankAccountData> {
    const url = `${environment.protections.declarationAPI}declarations/`;

    return new Promise<IBankAccountData>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}/bank-account`).subscribe(res => {
          resolve(res as IBankAccountData);
        },
        err => {
          reject(err);
        });
    });
  }

  setBankAccount(declarationUuid: string, bankAccount: IBankAccountData): Promise<IBankAccountData> {
    const url = `${environment.protections.declarationAPI}declarations/`;

    return new Promise<IBankAccountData>((resolve, reject) => {
      return this._http.put(
        `${url}${declarationUuid}/bank-account`,
        JSON.stringify(bankAccount),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IBankAccountData);
        },
        err => {
          reject(err);
        });
    });
  }

  public getSepaXML(declarationUuid: string): Promise<any> {
    const url = `${environment.protections.declarationAPI}declarations/`;

    return new Promise<any>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}/sepa`, {responseType: 'blob'}).subscribe(res => {
          resolve(res);
        },
        err => {
          reject(err);
        });
    });
  }

  public getRiskCSV(filters?: object): Promise<any> {
    let url = `${environment.protections.ordersApi}protections-csv`;
    const params = new Array<string>();

    if (filters) {
      for (const filter of Object.keys(filters)) {
        params.push(filter + '=' + encodeURI(filters[filter]));
      }

      url = `${url}?${params.join('&')}`.replace(/\+/g, '%2B');
    }

    return new Promise<any>((resolve, reject) => {
      return this._http.get(url, {responseType: 'blob'}).subscribe(res => {
          resolve(res);
        },
        err => {
          reject(err);
        });
    });
  }

  private async _asyncForEach(arr: Array<any>, callback: (x: any, i?: number, a?: Array<any>) => Promise<any>): Promise<any> {
    for (let i = 0; i < arr.length; i++) {
      await callback(arr[i], i, arr);
    }
  }
}
