import { Injectable } from '@angular/core';
import { ShopReferenceType } from '../../schemas/shop-reference-type';

@Injectable()
export class ShopReferenceService {
  public readonly channelRefRegex = /chl-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  public readonly TSIDRegex = /^X[A-Z0-9]{32}$/;

  constructor() { }

  public getType(id: string): ShopReferenceType {
    if (this.isChannelRef(id)) {
      return ShopReferenceType.CHANNEL_REF;
    }

    if (this.isTSID(id)) {
      return ShopReferenceType.TSID;
    }

    return null;
  }

  public isChannelRef(id: string): boolean {
    return !!id.toLowerCase().match(this.channelRefRegex);
  }

  public isTSID(id: string): boolean {
    return !!id.toUpperCase().match(this.TSIDRegex);
  }

}
