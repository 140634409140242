import { AbstractControl, ValidatorFn } from '@angular/forms';
import { IbanValidatorService } from '../services/iban-validator.service';

export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const validatorService = new IbanValidatorService();
    const ibanValid = validatorService.checkIbanValidity(control.value);
    return ibanValid ? null : {'ibanInvalid': {value: control.value}};
  };
}
