import { Injectable } from '@angular/core';
import { ApiKeyService } from '../api-key/api-key.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Review, ZendeskAPISingleReview } from '../../schemas/review';
import { ReviewSearchOptions } from '../../schemas/reviewSearchOptions';

@Injectable()
export class ReviewsService {

  constructor(
    private apiKeyService: ApiKeyService,
    private http: HttpClient
  ) { }

  public async getReviews(channelRef: string, searchBy: ReviewSearchOptions, searchValue?: string): Promise<Review[]> {
    let url: string;

    switch (searchBy) {
      case ReviewSearchOptions.EMAIL:
        url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/channels/${channelRef}?email=${searchValue}`;
        break;

      case ReviewSearchOptions.REVIEW_ID:
        url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/${searchValue}/internal`;
        break;

      case ReviewSearchOptions.SUBMITTED:
        url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/channels/${channelRef}/submitted?count=50`;
        break;
    }

    const apiKey = await this.apiKeyService.reviewServiceApiKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    try {
      return await this.http.get<any>(url, options)
        .pipe(
          map(data => {
            if (searchBy === ReviewSearchOptions.EMAIL || searchBy === ReviewSearchOptions.SUBMITTED) {
              return data.items || [];
            } else if (searchBy === ReviewSearchOptions.REVIEW_ID) {
              const review: Review = data;
              if (data.reply) {
                review.reply = {
                  comment: data.reply.comment,
                  lastEditedAt: data.reply.updatedAt
                };
              }
              review.transactionReference = (data.transaction && data.transaction.reference) ? data.transaction.reference : null;
              if (!data.items) {
                // hasAttachments value can be true, while there are no attachments if allowedToShow value is false for the images
                review.images = data.hasAttachments && data.attachments ? data.attachments.images.map(image => image.pathOriginal) : [];
              } else {
                review.images = data.images ? data.images.map(image => image.pathOriginal) : [];
              }
              return [review];
            }
          })
        )
        .toPromise();
    } catch (error) {
      console.error(error.message);
      let message = 'Sorry it\'s not you it\'s me. Please try again.';
      if (error instanceof HttpErrorResponse) {
        if (error.status === 403) {
          message = `API key is incorrect.`;
        } else if (error.status === 404) {
          return [];
        }
      }
      throw new Error(message);
    }
  }

  public async deleteShopReply(reviewId: string) {
    const url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/${reviewId}/reply/internal`;
    const apiKey = await this.apiKeyService.reviewServiceApiKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    try {
      return await this.http.delete<any>(url, options).toPromise();
    } catch (error) {
      console.error(error.message);
      let message = 'Sorry something went wrong while deleting the shop reply. Please try again.';
      if (error instanceof HttpErrorResponse) {
        if (error.status === 403) {
          message = `API key is incorrect.`;
        } else if (error.status === 404) {
          return;
        }
      }
      throw new Error(message);
    }
  }

  public async disallowImage(reviewId: string, attachmentId: string) {
    const url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/${reviewId}/attachments/${attachmentId}/disallow`;
    const apiKey = await this.apiKeyService.reviewServiceApiKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    try {
      return await this.http.put<any>(url, {}, options).toPromise();
    } catch (error) {
      console.error(error.message);
      let message = 'Sorry something went wrong while deleting the image. Please try again.';
      if (error instanceof HttpErrorResponse) {
        if (error.status === 403) {
          message = `API key is incorrect.`;
        } else if (error.status === 404) {
          message = `This functionality is not supported or not deployed.`;
        } else if (error.status === 304) {
          message = `This image has already been disallowed.`;
        }
      }
      throw new Error(message);
    }
  }
}
