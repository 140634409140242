export interface Review {
  'id': string;
  'rating': number;
  'title': string;
  'comment': string;
  'submittedAt': string;
  'state': ReviewState;
  'transactionReference': string;
  'images'?: string[];
  'type': ReviewType;
  'reply'?: {
    'comment': string;
    'lastEditedAt': string;
  };
}

export interface ZendeskAPISingleReview {
  'id': string;
  'rating': number;
  'title': string;
  'comment': string;
  'submittedAt': string;
  'state': ReviewState;
  'transaction': {
    'reference': string,
  };
  'type': ReviewType;
  'reply'?: {
    'comment': string;
    'updatedAt': string;
  };
  'hasAttachments': boolean;
  'attachments'?: {
    'images'?: {
      'pathOriginal': string;
    }[];
  };
}

export enum ReviewState {
  SUBMITTED = 'SUBMITTED',
  REPORTED = 'REPORTED',
  APPROVED = 'APPROVED',
  REAPPROVED = 'REAPPROVED',
  REJECTED = 'REJECTED',
  MODERATION =  'MODERATION',
  MODERATED =  'MODERATED',
  DISCARDED = 'DISCARDED',
}

export enum ReviewType {
  PRODUCT_REVIEW = 'PRODUCT_REVIEW',
  SERVICE_REVIEW = 'SERVICE_REVIEW',
}
