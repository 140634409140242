import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ReviewsDomUtilsService {
  constructor() {}

  toggleAllReviewCheckboxes(setToChecked: boolean): void {
    const reviewCheckboxes = document.querySelectorAll('.c-review__checkbox--single') as NodeListOf<HTMLInputElement>;
    reviewCheckboxes.forEach((checkbox) => {
      checkbox.checked = setToChecked;
    });
  }

  updateReviewsActionButtons(): void {
    const bulkApprovalButton = document.getElementById('BulkApprovalButton') as HTMLButtonElement;
    const selectAllReviewsButton = document.getElementById('SelectAllReviewsButton') as HTMLButtonElement;
    const unSelectAllReviewsButton = document.getElementById('UnSelectAllReviewsButton') as HTMLButtonElement;
    const totalNumberOfListedReviews = document.querySelectorAll('.c-review__checkbox--single').length;

    const selectedReviewIds = this.getSelectedReviewIds();
    const isAnyReviewSelected = selectedReviewIds.length > 0;
    const isAllReviewsSelected = totalNumberOfListedReviews === selectedReviewIds.length;

    bulkApprovalButton.disabled = !isAnyReviewSelected;
    selectAllReviewsButton.disabled = isAllReviewsSelected;
    unSelectAllReviewsButton.disabled = !isAnyReviewSelected;
  }

  getSelectedReviewIds(): string[] {
    // tslint:disable-next-line:max-line-length
    const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"].c-review__checkbox--single:checked') as NodeListOf<HTMLInputElement>,
      checkedCheckboxValues = [];

    checkedCheckboxes.forEach(checkbox => {
      if (checkbox.value) {
        checkedCheckboxValues.push(checkbox.value);
      }
    });

    return checkedCheckboxValues;
  }

  setDefaultReviewActionButtonsDisabledState(): void {
    const bulkApprovalButton = document.getElementById('BulkApprovalButton') as HTMLButtonElement;
    const selectAllReviewsButton = document.getElementById('SelectAllReviewsButton') as HTMLButtonElement;
    const unSelectAllReviewsButton = document.getElementById('UnSelectAllReviewsButton') as HTMLButtonElement;

    bulkApprovalButton.disabled = true;
    selectAllReviewsButton.disabled = false;
    unSelectAllReviewsButton.disabled = true;
  }
}
