import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { DocumentModalComponent } from '../document-modal/document-modal.component';
import { IControlCommitButton } from '../../../core/models/control-commit-button.interface';

@Component({
  selector: 'app-declaration-document',
  templateUrl: './declarations-document.component.html',
  styleUrls: ['declarations-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeclarationsDocumentComponent implements OnInit {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  //#endregion

  //#region Properties
  @Input() documentData: IDocumentDisplayData;

  @Input() declarationUuid: string;

  @Input() type: string;

  @Output() deleteDocument = new EventEmitter<IDocumentDisplayData>();

  @ViewChild(DocumentModalComponent) documentModal: DocumentModalComponent;

  private _showProgress = false;
  public get showProgress(): boolean {
    return this._showProgress;
  }

  private _showLoading = false;
  public get showLoading(): boolean {
    return this._showLoading;
  }

  private _showImage = false;
  public get showImage(): boolean {
    return this._showImage;
  }

  private _showError = false;
  public get showError(): boolean {
    return this._showError;
  }

  private _percentage = 0;
  public get percentage(): number {
    return this._percentage;
  }

  private _isDeleting = false;
  public get isDeleting(): boolean {
    return this._isDeleting;
  }

  private _disableDelete: boolean;
  @Input()
  public get disableDelete(): boolean {
    return this._disableDelete;
  }
  public set disableDelete(v: boolean) {
    this._disableDelete = v;
  }

  private _attachable: boolean;
  @Input()
  public get attachable(): boolean {
    return this._attachable;
  }
  public set attachable(v: boolean) {
    this._attachable = v;
  }

  private _isAttached: boolean;
  public get isAttached(): boolean {
    return this._isAttached;
  }
  //#endregion

  constructor(declarationService: DeclarationService) {
    this._declarationService = declarationService;
  }

  ngOnInit() {
    this._declarationService.controlCommitButton.subscribe(controlCommitButton => {
      this._isAttached = this.documentData.document &&
        this.type === 'SHOP' &&
        controlCommitButton.attachToShopDocumentMessage.find(x => x.uuid === this.documentData.document.uuid) !== undefined ||
        this.documentData.document &&
        this.type !== 'SHOP' &&
        controlCommitButton.attachToConsumerDocumentMessage.find(x => x.uuid === this.documentData.document.uuid) !== undefined;
    });

    if (this.documentData.error !== undefined) {
      this._showError = true;
    } else if (this.documentData.progress === undefined && this.documentData.document !== undefined) {
      this._showImage = true;
    } else {
      this._showProgress = true;
      this.documentData.progress.subscribe(next => {
        this._percentage = next;
      }, error => {
        this.documentData.error = 'GENERAL_ERROR';
        this._showProgress = false;
        this._showError = true;
      }, () => {
        this._showProgress = false;
        if (this.documentData.error !== undefined) {
          this._showError = true;
        } else {
          let attachToMessage;
          if (this.type === 'SHOP') {
            attachToMessage = this._declarationService.controlCommitButton.value.attachToShopDocumentMessage;
          } else {
            attachToMessage = this._declarationService.controlCommitButton.value.attachToConsumerDocumentMessage;
          }

          attachToMessage.push({
            action: 'UPLOAD',
            uuid: this.documentData.document.uuid
          });

          const currentState = this._declarationService.controlCommitButton.value;
          if (this.type === 'SHOP') {
            this._declarationService.controlCommitButton.next(Object.assign(
              {},
              currentState,
              {attachToShopDocumentMessage: attachToMessage} as IControlCommitButton
            ));
          } else {
            this._declarationService.controlCommitButton.next(Object.assign(
              {},
              currentState,
              {attachToConsumerDocumentMessage: attachToMessage} as IControlCommitButton
            ));
          }
          this._showImage = true;
        }
      });
    }
  }

  stripFileName(name: string): string {
    if (name.length > 10) {
      return `${name.substr(0, 5)}...${name.substr(name.length - 4)}`;
    } else {
      return name;
    }
  }

  deleteDoc(e) {
    e.stopPropagation();
    this._isDeleting = true;

    const attachToShopDocumentMessage = this._declarationService.controlCommitButton.value.attachToShopDocumentMessage;
    const index = attachToShopDocumentMessage.indexOf(attachToShopDocumentMessage.find(x => x.uuid === this.documentData.document.uuid));

    if (index >= 0) {
      attachToShopDocumentMessage.splice(index, 1);

      const currentState = this._declarationService.controlCommitButton.value;
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {attachToShopDocumentMessage} as IControlCommitButton
      ));
    }

    this.deleteDocument.emit(this.documentData);
    setTimeout(() => this._isDeleting = false, 10000);
  }

  toggleAttach() {
    const currentState = this._declarationService.controlCommitButton.value;
    let attachToMessage = [];
    if ((currentState.commitTypeShop === 'DOCUMENT_QUERY' || currentState.commitTypeShop === 'SIMPLE_MESSAGE') && this.type === 'SHOP') {
      attachToMessage = currentState.attachToShopDocumentMessage;
    } else if (this.type === 'BUYER') {
      attachToMessage = currentState.attachToConsumerDocumentMessage;
    }

    const index = attachToMessage.indexOf(attachToMessage.find(x => x.uuid === this.documentData.document.uuid));

    if (index >= 0) {
      attachToMessage.splice(index, 1);
    } else {
      attachToMessage.push({
        action: 'SHARED',
        uuid: this.documentData.document.uuid
      });
    }

    if ((currentState.commitTypeShop === 'DOCUMENT_QUERY' || currentState.commitTypeShop === 'SIMPLE_MESSAGE') && this.type === 'SHOP') {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {attachToShopDocumentMessage: attachToMessage} as IControlCommitButton
      ));
    } else if (this.type === 'BUYER') {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {attachToConsumerDocumentMessage: attachToMessage} as IControlCommitButton
      ));
    }
  }

  showDocument() {
    this.documentModal.openModal();
  }
}

