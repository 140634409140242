import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import 'moment/locale/de';
import { IDeclarationMessage } from '../../../../core/models/declarationMessage.interface';
import { UserService } from '../../../../../../core/services/user.service';
import { DeclarationService } from '../../../../core/services/declaration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comments-info',
  templateUrl: './comments-info.component.html',
  styleUrls: ['../info-cards.component.scss']
})
export class CommentsInfoComponent implements OnInit {
  private readonly _userService: UserService;

  //#region Properties

  private _comments: IDeclarationMessage[];
  public get comments(): IDeclarationMessage[] {
    return this._comments;
  }
  @Input() public set comments(v: IDeclarationMessage[]) {
    this._comments = v;
  }

  private _writeMessage = new EventEmitter<any>();
  @Output() public get writeMessage(): EventEmitter<any> {
    return this._writeMessage;
  }

  private _scrollDown = new EventEmitter<void>();
  @Output() public get scrollDown(): EventEmitter<any> {
    return this._scrollDown;
  }

  private _isServiceUser = false;
  public get isServiceUser(): boolean {
    return this._isServiceUser;
  }
  //#endregion

  constructor(userService: UserService) {
    this._userService = userService;
  }

  ngOnInit(): void {
    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isServiceUser = roles.includes('ts_service') || roles.includes('myts_ts_service');
    });
  }

  createComment() {
    this._writeMessage.emit();
  }
}
