import { Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { DeclarationService } from '../../../core/services/declaration.service';
import { IDeclaration } from '../../../core/models/declaration.interface';
import { IDeclarationMessage } from '../../../core/models/declarationMessage.interface';
import { IDecision } from '../../../core/models/decision.interface';
import { IProtectionInfo } from '../../../core/models/protectionInfo.interface';
import { DeclarationState } from '../../../core/models/declarationState.enum';
import { ITimer } from '../../../core/models/timer.interface';
import { TimerType } from '../../../core/models/timerType.enum';
import { IMessageStreamItem } from '../../../core/models/messageStreamItem.interface';
import { IProtectionHistoryItem } from '../../../core/models/protectionHistoryItem.interface';
import { IDocument } from '../../../core/models/document.interface';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserService } from '../../../../../core/services/user.service';
import { IControlCommitButton } from '../../../core/models/control-commit-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { SearchFilterService } from '../../../core/services/search-filter.service';
import { IProtectionsRequestFilter } from '../../../core/models/protectionsRequestFilter.interface';
import { IProtection } from '../../../core/models/protection.interface';

@Component({
  selector: 'app-protection-details',
  templateUrl: './protection-details.component.html',
  styleUrls: ['./protection-details.component.scss'],
  animations: [
    trigger('open', [
      state('false', style({
        height: 0,
        padding: 0
      })),
      state('true', style({
        height: '60px',
        padding: '15px 0'
      })),
      state('error', style({
        height: '105px',
        padding: '15px 0'
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ]),
    trigger('showAssignment', [
      state('false', style({
        opacity: 0
      })),
      state('true', style({
        opacity: 1
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ]),
    trigger('showRightPlaymodeLink', [
      state('false', style({
        backgroundColor: 'rgba(0, 0, 0, 0)'
      })),
      state('true', style({
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      })),
      transition('* => *', animate('0.5s ease-in-out'))
    ]),
    trigger('showLeftPlaymodeLink', [
      state('false', style({
        backgroundColor: 'rgba(0, 0, 0, 0)'
      })),
      state('true', style({
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      })),
      transition('* => *', animate('0.5s ease-in-out'))
    ])
  ]
})
export class ProtectionDetailsComponent implements OnInit, OnDestroy {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  private readonly _route: ActivatedRoute;
  private readonly _userService: UserService;
  private readonly _translateService: TranslateService;
  private readonly _searchFilterService: SearchFilterService;
  private readonly _router: Router;
  private _tryToAssign = true;
  private readonly _window: any;
  private _shopTimeout = false;
  //#endregion

  //#region Properties
  @ViewChild(ModalDirective) commentModal: ModalDirective;

  @ViewChild(ErrorModalComponent) errorModal: ErrorModalComponent;

  private _declarationUuid: string;
  public get declarationUuid(): string {
    return this._declarationUuid;
  }

  private _protectionId: string;
  public get protectionId(): string {
    return this._protectionId;
  }

  private _protectionInfo: IProtectionInfo;
  public get protectionInfo(): IProtectionInfo {
    return this._protectionInfo;
  }

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }

  private _protectionDeclaration: IDeclaration = {};
  public get protectionDeclaration(): IDeclaration {
    return this._protectionDeclaration;
  }

  private _userMessages: Array<IMessageStreamItem> = [];
  public get userMessages(): Array<IMessageStreamItem> {
    return this._userMessages;
  }

  private _shopMessages: Array<IMessageStreamItem> = [];
  public get shopMessages(): Array<IMessageStreamItem> {
    return this._shopMessages;
  }

  private _internalMessages: Array<IDeclarationMessage> = [];
  public get internalMessages(): Array<IDeclarationMessage> {
    return this._internalMessages;
  }

  private _messages: Array<IDeclarationMessage>;
  public get messages(): Array<IDeclarationMessage> {
    return this._messages;
  }

  private _modalHeadline = 'ADD_COMMENT';
  public get modalHeadline(): string {
    return this._modalHeadline;
  }

  private _modalButton = 'SAVE_COMMENT';
  public get modalButton(): string {
    return this._modalButton;
  }

  private _comment = '';
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;
  }

  private _declarationDate: number;
  public get declarationDate(): number {
    return this._declarationDate;
  }

  private _actionsList: IProtectionHistoryItem[];
  public get actionsList(): IProtectionHistoryItem[] {
    return this._actionsList;
  }

  private _documents: Array<IDocument>;
  public get documents(): Array<IDocument> {
    return this._documents;
  }

  private _showCommitButton = 'false';
  public get showCommitButton(): string {
    return this._showCommitButton;
  }

  private _commitButtonEnabled: boolean;
  public get commitButtonEnabled(): boolean {
    return this._commitButtonEnabled;
  }

  private _isLoading = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _showAssignment = false;
  public get showAssignment(): boolean {
    return this._showAssignment;
  }

  private _blockMessage = false;
  public get blockMessage(): boolean {
    return this._blockMessage;
  }

  private _assigneeUuid: string;
  public get assigneeUuid(): string {
    return this._assigneeUuid;
  }

  private _assignError = false;
  public get assignError(): boolean {
    return this._assignError;
  }

  private _scrolled = new EventEmitter<void>();
  public get scrolled(): EventEmitter<void> {
    return this._scrolled;
  }

  private _closedByService = false;
  public get closedByService(): boolean {
    return this._closedByService;
  }

  private _closedByBuyer = false;
  public get closedByBuyer(): boolean {
    return this._closedByBuyer;
  }

  private _closedByBuyerTimeout = false;
  public get closedByBuyerTimeout(): boolean {
    return this._closedByBuyerTimeout;
  }

  private _showFastForward = false;
  public get showFastForward(): boolean {
    return this._showFastForward ;
  }

  private _migratedLastAction: string;
  public get migratedLastAction(): string {
    return this._migratedLastAction ;
  }

  private _filter: IProtectionsRequestFilter = {
    declarationStates: 'TS_CHECK',
    offset: 0
  };
  public get filter(): IProtectionsRequestFilter {
    return this._filter;
  }

  private _previousProtection: IProtection;
  public get previousProtection(): IProtection {
    return this._previousProtection ;
  }

  private _nextProtection: IProtection;
  public get nextProtection(): IProtection {
    return this._nextProtection ;
  }

  private _offset: number;
  public get offset(): number {
    return this._offset ;
  }

  private _isServiceUser = false;
  public get isServiceUser(): boolean {
    return this._isServiceUser;
  }

  showRightPlaymodeLink = false;

  showLeftPlaymodeLink = false;

  showHighRefundWarning = false;
  //#endregion

  constructor(declarationService: DeclarationService,
              route: ActivatedRoute,
              userService: UserService,
              @Inject('window') window: any,
              translateService: TranslateService,
              searchFilterService: SearchFilterService,
              router: Router) {
    this._declarationService = declarationService;
    this._route = route;
    this._userService = userService;
    this._window = window;
    this._translateService = translateService;
    this._searchFilterService = searchFilterService;
    this._router = router;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this._declarationService.controlCommitButton.subscribe(
      controlCommitButton => {
        if ((controlCommitButton.buttonShownForShop || controlCommitButton.buttonShownForBuyer) &&
        (this._showCommitButton === 'false' || this._showCommitButton === 'error')) {
          this._showCommitButton = 'true';
        }
        this._commitButtonEnabled = controlCommitButton.shopDocumentMessage && controlCommitButton.shopDocumentMessage.length > 3 ||
          controlCommitButton.consumerDocumentMessage && controlCommitButton.consumerDocumentMessage.length > 3 ||
          controlCommitButton.commitTypeBuyer === 'DECISION_NOT_REASONABLE' &&
            controlCommitButton.decisionMessage && controlCommitButton.decisionMessage.length > 3 ||
          controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE' &&
            controlCommitButton.decisionMessage && controlCommitButton.decisionMessage.length > 3 &&
            controlCommitButton.refundAmount && controlCommitButton.refundAmount > 0 ||
          controlCommitButton.commitTypeBuyer === 'DECISION_GOODWILL' &&
            controlCommitButton.decisionMessage && controlCommitButton.decisionMessage.length > 3 &&
            controlCommitButton.refundAmount && controlCommitButton.refundAmount > 0;

        if (this._showCommitButton === 'true' &&
          (controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE' || controlCommitButton.commitTypeBuyer === 'DECISION_GOODWILL') &&
          controlCommitButton.refundAmount > this._protectionInfo.protectionInfo.orderAmount) {
          this._showCommitButton = 'error';
        }
        this.showHighRefundWarning = this._showCommitButton === 'error' &&
          (controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE' || controlCommitButton.commitTypeBuyer === 'DECISION_GOODWILL') &&
          controlCommitButton.refundAmount > this._protectionInfo.protectionInfo.orderAmount;
      }
    );
    this._route.paramMap.subscribe(async (params: ParamMap) => {
      try {
        this._protectionId = params.get('id');
        this._declarationUuid = params.get('declaration');
        if (params.get('offset')) {
          if (isNaN(+params.get('offset'))) {
            this._declarationUuid = params.get('offset');
          } else {
            this._offset = +params.get('offset');
          }
        }

        if (this._searchFilterService.hasAutoFilter) {
          this._filter = this._searchFilterService.autoFilter;
        }
        if (!this._filter.searchEmployeeUuid) {
          this._filter.searchEmployeeUuid = '-1';
        }

        if (this._offset !== undefined) {
          await this.loadPlayMode();
        }

        if (this._declarationUuid) {
          this._loadData();
        } else {
          this._loadOnlyProtection();
        }
      } catch (e) { }
    });

    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isServiceUser = roles.includes('ts_service') || roles.includes('myts_ts_service');
      this._showFastForward = this._isServiceUser;
    });
  }

  ngOnDestroy() {
    this._declarationService.controlCommitButton.next({
      buttonShownForShop: false,
      buttonShownForBuyer: false,
      attachToShopDocumentMessage: [],
      attachToConsumerDocumentMessage: [],
      assignedToOtherUser: '',
      commitTypeBuyer: '',
      commitTypeShop: ''
    });
  }

  private async _loadOnlyProtection() {
    this._isLoading = true;
    this._protectionInfo = await this._declarationService.getProtectionInfo(this._protectionId);

    await this._loadHistory();

    if (this._protectionInfo.protectionInfo.protectionState === 'CLOSED' ||
      this._declaration && this._declaration.state === DeclarationState.CANCELED) {
      this._userMessages = [{
        sender: 'NONE',
        headline: this._closedByBuyer ? 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER' : 'MESSAGE_STREAM.PROTECTION_CLOSED',
        initiallyOpen: true,
        showBorder: false,
      }];
      this._shopMessages = [{
        sender: 'NONE',
        headline: this._closedByBuyer ? 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER' : 'MESSAGE_STREAM.PROTECTION_CLOSED',
        initiallyOpen: true,
        showBorder: false,
      }];
    } else {
      this._userMessages = [{
        message: 'MESSAGE_STREAM.ACTIVE_PROTECTION',
        translateMessage: true,
        sender: 'EMPLOYEE',
        initiallyOpen: true,
        showBorder: false,
        hideToggle: true,
        expirationDate: new Date(this._protectionInfo.protectionInfo.expirationDate).getTime()
      }];
      this._shopMessages = [{
        message: 'MESSAGE_STREAM.ACTIVE_PROTECTION',
        translateMessage: true,
        sender: 'EMPLOYEE',
        initiallyOpen: true,
        showBorder: false,
        hideToggle: true,
        expirationDate: new Date(this._protectionInfo.protectionInfo.expirationDate).getTime()
      }];
    }

    this._isLoading = false;
  }

  private async _loadData() {
    try {
      this._isLoading = true;
      this._protectionInfo = await this._declarationService.getProtectionInfo(this._protectionId);
      this._declaration = await this._declarationService.getDeclaration(this._declarationUuid);
      this._protectionDeclaration = Object.assign({}, this._declaration);
      this._migratedLastAction = this._declaration.oldState;

      if (this._declaration.state === DeclarationState.CANCELED || this._declaration.state === DeclarationState.DECLARING) {
        this._loadOnlyProtection();
        return;
      }

      if (!this._declaration.currency) {
        this._declaration.currency = this._protectionInfo.protectionInfo.orderCurrency;
      }

      if (this._declaration.assignee) {
        this._tryToAssign = false;
        this._protectionInfo.protectionInfo.assignee = `${this._declaration.assignee.firstname} ${this._declaration.assignee.lastname}`;
        this._assigneeUuid = this._declaration.assignee.uuid;
      }

      this._documents = await this._declarationService.getDocuments(this._declarationUuid);
      this._messages = await this._declarationService.getMessagesForDeclaration(this._declarationUuid);

      this._messages = await this._parseMessages(this._messages);
      this._internalMessages = this._messages.filter(message => message.senderRole === 'EMPLOYEE'
        && message.recipientRole === 'EMPLOYEE').reverse();

      await this._loadHistory();

      this._userMessages = await this.buildMessageStream(this._messages.filter(message => message.senderRole === 'CONSUMER'
        || message.recipientRole === 'CONSUMER'), 'BUYER');
      this._shopMessages = await this.buildMessageStream(this._messages.filter(message => message.senderRole === 'SHOP'
        || message.recipientRole === 'SHOP'), 'SHOP');

      if (this._userMessages && this._userMessages.length) {
        this._userMessages[this._userMessages.length - 1].initiallyOpen = true;
      }
      if (this._shopMessages && this._shopMessages.length) {
        this._shopMessages[this._shopMessages.length - 1].initiallyOpen = true;
      }

      this._isLoading = false;

      this._declarationDate = new Date(this._declaration.creationDate).getTime();

    } catch (e) {
      console.log(e);
      this._isLoading = false;
    }
  }

  async loadPlayMode() {
    try {
      this._filter.offset = this._offset === 0 ? 0 : this._offset - 1;
      this._filter.limit = 3;
      const searchFilter = Object.assign({}, this._filter);
      if (searchFilter.searchEmployeeUuid === '-1') {
        delete searchFilter.searchEmployeeUuid;
      }

      if (searchFilter.searchEmployeeUuid === 'no_editor') {
        delete searchFilter.searchEmployeeUuid;
        searchFilter.unassigned = true;
      }
      const list = await this._declarationService.getProtections(searchFilter);
      delete this._filter.offset;
      delete this._filter.limit;

      const protectionIndex = this._offset === 0 ? 0 : 1;
      this._previousProtection = list.declarations[protectionIndex - 1];
      this._nextProtection = list.declarations[protectionIndex + 1];
    } catch {}
  }

  navigateInPlayMode(url: string) {
    this._comment = '';
    this.showRightPlaymodeLink = false;
    this.showLeftPlaymodeLink = false;
    this._declarationService.controlCommitButton.next({
      buttonShownForShop: false,
      buttonShownForBuyer: false,
      attachToShopDocumentMessage: [],
      attachToConsumerDocumentMessage: [],
      assignedToOtherUser: '',
      commitTypeBuyer: '',
      commitTypeShop: ''
    });
    this._router.navigateByUrl(url);
  }

  showModal() {
    this._modalHeadline = 'ADD_COMMENT';
    this._modalButton = 'SAVE_COMMENT';
    this.commentModal.show();
  }

  private async _parseMessages(messages: Array<IDeclarationMessage>): Promise<Array<IDeclarationMessage>> {
    return await Promise.all(messages.map(async message => {
      if (message.message === null || message.message === undefined) {
        message.message = '';
      }
      const translations = message.message.match(/__translate__[A-Z._]+/gm);
      if (translations) {
        const translationKeys = translations.map(key => key.substr(24));
        const translated: string[] = await new Promise(resolve => {
          this._translateService.get(translationKeys).subscribe(values => {
            resolve(translationKeys.map(key => values[key]));
          });
        });
        translated.forEach((key, index) => {
          message.message = message.message.replace(translations[index], key);
        });
      }
      return message;
    }));
  }

  async saveComment() {
    this.commentModal.hide();
    this._isLoading = true;
    try {
      const message: IDeclarationMessage = {
        message: this._comment,
        senderRole: 'EMPLOYEE',
        recipientRole: 'EMPLOYEE',
        commitCycle: this._declaration.commitCycle
      };
      const tempMessage = await this._declarationService.createMessageForDeclaration(this._declarationUuid, message);
      this._internalMessages.unshift(tempMessage);
      this._comment = '';

      this._loadHistory();
    } catch (e) {
      this.errorModal.openModal();
      this._isLoading = false;
    }
  }

  async saveAndCommit() {
    try {
      this._isLoading = true;
      const controlCommitButton = this._declarationService.controlCommitButton.value;
      let allowToShop = false;
      let allowToConsumer = false;

      if (controlCommitButton.commitTypeBuyer === 'DECISION_NOT_REASONABLE') {
        const decision: IDecision = {
          amountRefund: 0,
          goodwill: false,
          reason: controlCommitButton.decisionMessage,
          reasonable: false
        };
        if (controlCommitButton.attachToConsumerDocumentMessage.length > 0) {
          await this.createDocumentsMessage();
        }
        await this._declarationService.declarationCommitCompleteness(this._declarationUuid, false, false);
        await this._declarationService.declarationCreateUpdateDecision(this._declarationUuid, decision);
        this._declaration = await this._declarationService.declarationCommitDecision(this._declarationUuid);
      } else if (controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE' ||
                  controlCommitButton.commitTypeBuyer === 'DECISION_GOODWILL') {
        const decision: IDecision = {
          amountRefund: controlCommitButton.refundAmount,
          goodwill: controlCommitButton.commitTypeBuyer === 'DECISION_GOODWILL',
          reason: controlCommitButton.decisionMessage,
          reasonable: controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE'
        };
        if (controlCommitButton.commitTypeBuyer === 'DECISION_REASONABLE') {
          decision.serviceFee = controlCommitButton.includeServiceFee;
        }

        if (controlCommitButton.attachToConsumerDocumentMessage.length > 0) {
          await this.createDocumentsMessage();
        }
        await this._declarationService.declarationCommitCompleteness(this._declarationUuid, false, false);
        await this._declarationService.declarationCreateUpdateDecision(this._declarationUuid, decision);
        this._declaration = await this._declarationService.declarationCommitDecision(this._declarationUuid);
      } else if (controlCommitButton.commitTypeBuyer === 'DOCUMENT_QUERY' ||
                  controlCommitButton.commitTypeShop === 'DOCUMENT_QUERY' ||
                  controlCommitButton.commitTypeBuyer === 'SIMPLE_MESSAGE' ||
                  controlCommitButton.commitTypeShop === 'SIMPLE_MESSAGE' ) {
        if (controlCommitButton.shopDocumentMessage &&
          controlCommitButton.shopDocumentMessage.length > 0) {
          const shopMessage = await this._declarationService.createMessageForDeclaration(this._declarationUuid, {
            senderRole: 'EMPLOYEE',
            recipientRole: 'SHOP',
            message: controlCommitButton.shopDocumentMessage
          });

          const uploadedDocments = controlCommitButton.attachToShopDocumentMessage
            .filter(x => x.action === 'UPLOAD')
            .map(x => x.uuid);
          if (uploadedDocments.length > 0) {
            await this._declarationService.attachDocumentToMessage(this._declarationUuid, shopMessage.uuid, uploadedDocments, 'UPLOAD');
          }

          const sharedDocments = controlCommitButton.attachToShopDocumentMessage
            .filter(x => x.action === 'SHARED')
            .map(x => x.uuid);
          if (sharedDocments.length > 0) {
            await this._declarationService.attachDocumentToMessage(this._declarationUuid, shopMessage.uuid, sharedDocments, 'SHARED');
          }

          allowToShop = controlCommitButton.commitTypeShop === 'DOCUMENT_QUERY';

          if (controlCommitButton.commitTypeShop === 'SIMPLE_MESSAGE') {
            await this._declarationService.commitMessage(this._declarationUuid, shopMessage.uuid);
          }
        }

        if (controlCommitButton.consumerDocumentMessage &&
          controlCommitButton.consumerDocumentMessage.length > 0) {
          const consumerMessage = await this._declarationService.createMessageForDeclaration(this._declarationUuid, {
            senderRole: 'EMPLOYEE',
            recipientRole: 'CONSUMER',
            message: controlCommitButton.consumerDocumentMessage
          });

          const uploadedDocments = controlCommitButton.attachToConsumerDocumentMessage
            .filter(x => x.action === 'UPLOAD')
            .map(x => x.uuid);
          if (uploadedDocments.length > 0) {
            await this._declarationService.attachDocumentToMessage(this._declarationUuid,
              consumerMessage.uuid,
              uploadedDocments,
              'UPLOAD');
          }

          const sharedDocments = controlCommitButton.attachToConsumerDocumentMessage
            .filter(x => x.action === 'SHARED')
            .map(x => x.uuid);
          if (sharedDocments.length > 0) {
            await this._declarationService.attachDocumentToMessage(this._declarationUuid, consumerMessage.uuid, sharedDocments, 'SHARED');
          }

          allowToConsumer = controlCommitButton.commitTypeBuyer === 'DOCUMENT_QUERY';

          if (controlCommitButton.commitTypeBuyer === 'SIMPLE_MESSAGE') {
            await this._declarationService.commitMessage(this._declarationUuid, consumerMessage.uuid);
          }
        }

        if (allowToConsumer || allowToShop) {
          await this._declarationService.declarationCommitCompleteness(this._declarationUuid, allowToConsumer, allowToShop);
        }
      }

      this._loadData();
      this._declarationService.controlCommitButton.next({
        buttonShownForShop: false,
        buttonShownForBuyer: false,
        attachToShopDocumentMessage: [],
        attachToConsumerDocumentMessage: [],
        assignedToOtherUser: '',
        commitTypeBuyer: '',
        commitTypeShop: ''
      });
    } catch (e) {
      this.errorModal.openModal();
      this._isLoading = false;
    }
  }

  async fastForward() {
    try {
      if (this._declaration.state === DeclarationState.DECLARED || this._declaration.state === 'FEEDBACK_NEEDED_SHOP') {
        this._isLoading = true;
        const timer: ITimer = {
          timer: TimerType.PROVIDE_FEEDBACK_SHOP
        };

        this._declaration = await this._declarationService.endTimer(this._declarationUuid, timer);
        this._loadData();
      } else if (this._declaration.state === 'SHOP_ANSWERED') {
        this._isLoading = true;
        const timer: ITimer = {
          timer: TimerType.PROVIDE_FEEDBACK_CONSUMER
        };

        this._declaration = await this._declarationService.endTimer(this._declarationUuid, timer);
        this._loadData();
      } else if (this._declaration.state === 'FEEDBACK_NEEDED_CONSUMER') {
        this._isLoading = true;
        const timer: ITimer = {
          timer: TimerType.PROVIDE_ADDITIONAL_DOCUMENTS_CONSUMER
        };

        this._declaration = await this._declarationService.endTimer(this._declarationUuid, timer);
        this._loadData();
      }
    } catch (e) {
      this.errorModal.openModal();
      this._isLoading = false;
    }
  }

  private async buildMessageStream(messages: Array<IDeclarationMessage>, type: string): Promise<Array<IMessageStreamItem>> {
    const messageItems = new Array<IMessageStreamItem>();

    if (messages.length === 0 && this._declaration.state === DeclarationState.CLOSED) {
      messageItems.push({
        sender: 'NONE',
        headline: this._closedByBuyer ? 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER' : 'MESSAGE_STREAM.PROTECTION_CLOSED',
        initiallyOpen: true,
        showBorder: false,
        declarationUuid: this._declaration.declarationUuid
      });

      return messageItems;
    }

    if (this._declaration.migrated && messages[0].senderRole !== 'CONSUMER') {
      messageItems.push({
        sender: 'CONSUMER',
        userEmail: this._protectionInfo.buyerInfo.consumerEmail,
        initiallyOpen: true,
        showBorder: false,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    for (const declarationMessage of messages) {
      messageItems.push({
        creationDate: new Date(declarationMessage.commitDate !== null ? declarationMessage.commitDate : declarationMessage.creationDate),
        message: declarationMessage.message,
        messageUuid: declarationMessage.uuid,
        translateMessage: false,
        sender: declarationMessage.senderRole,
        initiallyOpen: false,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid,
        userEmail: declarationMessage.senderRole === 'CONSUMER' ? this._protectionInfo.buyerInfo.consumerEmail : null,
        shopName: declarationMessage.senderRole === 'SHOP' ? this._protectionInfo.shopInfo.shop.url : null
      });
    }

    let translateString = 'MESSAGE_STREAM.NO_DELIVERY_HEADLINE'; // No delivery
    if (this._declaration.reason === 'RNR') {
      translateString = 'MESSAGE_STREAM.NO_REFUND_HEADLINE'; // No refund
      messageItems[0].revocationDate = this._declaration.cancellationDate;
    }

    messageItems[0].headline = translateString;

    /* ************************************ */
    /* Declared and waiting for shop answer */
    /* ************************************ */

    if (type === 'BUYER' && this._declaration.state === DeclarationState.DECLARED) {
      messageItems.push({
        message: 'MESSAGE_STREAM.WAITING_FOR_SHOP',
        translateMessage: true,
        sender: 'EMPLOYEE',
        headline: 'MESSAGE_STREAM.WAITING_FOR_SHOP_HEADLINE',
        initiallyOpen: false,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    /* ************************************ */
    /* Shop answered */
    /* ************************************ */

    if (this._declaration.feedbackShop) {
      if (this._declaration.migrated && (messages.length === 0 || messages[0].senderRole !== 'SHOP')) {
        messageItems.splice(1, 0, {
          sender: 'SHOP',
          initiallyOpen: true,
          showBorder: true,
          declarationUuid: this._declaration.declarationUuid,
          shopName: this._protectionInfo.shopInfo.shop.url
        });
      }
      let headline: string;
      if (this._declaration.feedbackShop === 'REFUND') {
        headline = 'MESSAGE_STREAM.SHOP_WILL_REFUND';
      } else if (this._declaration.feedbackShop === 'NON_REFUND') {
        headline = 'MESSAGE_STREAM.SHOP_WONT_REFUND';
      } else if (this._declaration.feedbackShop === 'REFUND_LATER') {
        headline = 'MESSAGE_STREAM.SHOP_WILL_REFUND_LATER';
      } else if (this._declaration.feedbackShop === 'PARTIAL_REFUND_LATER') {
        messageItems[1].refundAmount = this._declaration.amountRefund;
        messageItems[1].currency = this._declaration.currency;
        headline = 'MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND_LATER';
      } else {
        messageItems[1].refundAmount = this._declaration.amountRefund;
        messageItems[1].currency = this._declaration.currency;
        headline = 'MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND';
      }

      messageItems[1].headline = headline;
    }

    /* ******************************************* */
    /* Shop did not answer or migrated with answer */
    /* ******************************************* */

    if (this._shopTimeout || !this._declaration.feedbackShop &&
      this._declaration.state !== DeclarationState.DECLARED &&
      this._declaration.oldState !== 'SHOP_INFO_PROVIDED' &&
      messages.filter(m => m.senderRole === 'EMPLOYEE').length > 0) {
      const headline = await new Promise(resolve => {
        this._translateService.get(type === 'SHOP' ? 'MESSAGE_STREAM.SHOP_DID_NOT_ANSWER' : 'MESSAGE_STREAM.NO_ANSWER_SHOP_HEADLINE',
          {shop: this._protectionInfo.shopInfo.shop.name})
          .subscribe(value => {
            resolve(value);
          });
      }) as string;
      const message =  type === 'SHOP' ? 'MESSAGE_STREAM.TS_CHECK_STATEMENT' : 'MESSAGE_STREAM.NO_ANSWER_SHOP_QUESTION';

      messageItems.splice(1, 0, {
        headline,
        message,
        translateMessage: true,
        translateHeadline: true,
        sender: 'EMPLOYEE',
        initiallyOpen: false,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });
    } else if (!this._declaration.feedbackShop &&
      this._declaration.state !== DeclarationState.DECLARED &&
      this._declaration.oldState === 'SHOP_INFO_PROVIDED' &&
      type === 'SHOP') {
      messageItems.splice(1, 0, {
        headline: 'MESSAGE_STREAM.TS_CHECK_HEADLINE',
        message: 'MESSAGE_STREAM.TS_CHECK_TEXT',
        translateMessage: true,
        sender: 'EMPLOYEE',
        initiallyOpen: false,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    /* ************************************ */
    /* Waiting for bank account */
    /* ************************************ */

    if (type === 'SHOP' && this._declaration.state === DeclarationState.WAITING_FOR_BANKACCOUNT_INFO) {
      messageItems.push({
        headline: 'MESSAGE_STREAM.TS_CHECK_HEADLINE',
        message: 'MESSAGE_STREAM.TS_CHECK_TEXT',
        translateMessage: true,
        sender: 'EMPLOYEE',
        initiallyOpen: false,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    /* ************************************ */
    /* Refund not received / accepted */
    /* ************************************ */

    if (this._declaration.refundReceived && this._declaration.refundReceived !== 'RECEIVED') {
      if (this._declaration.refundReceived === 'NOT_RECEIVED') {
        messageItems.splice(2, 0, {
          headline: 'MESSAGE_STREAM.NOT_RECEIVED',
          translateMessage: true,
          sender: 'CONSUMER',
          initiallyOpen: false,
          deliveryDate: this._declaration.actualDeliveryDate,
          showBorder: true,
          declarationUuid: this._declaration.declarationUuid,
          userEmail: this._protectionInfo.buyerInfo.consumerEmail
        });
      } else {
        messageItems[2].headline = 'MESSAGE_STREAM.NOT_ACCEPTED';
      }
    }

    /* ************************************ */
    /* Trusted Shops is checking */
    /* ************************************ */

    if (type === 'BUYER' &&
      (this._declaration.state === DeclarationState.TS_CHECK || this._declaration.state === DeclarationState.FEEDBACK_NEEDED_SHOP) &&
      (!!this._declaration.feedbackShop || messages.length > 1) ||
      !this._declaration.feedbackShop &&
      this._declaration.state !== DeclarationState.DECLARED &&
      (this._declaration.oldState === 'SHOP_INFO_PROVIDED' || !this._declaration.oldState)) {

      messageItems.push({
        headline: 'MESSAGE_STREAM.TS_CHECK',
        message: 'MESSAGE_STREAM.TS_CHECK_STATEMENT',
        translateMessage: true,
        sender: 'EMPLOYEE',
        initiallyOpen: true,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    /* ************************************ */
    /* Protection closed */
    /* ************************************ */

    if (this._declaration.state === DeclarationState.CLOSED && !this._declaration.decision) {
      let headline: string;
      headline = 'MESSAGE_STREAM.PROTECTION_CLOSED';
      if (this._closedByService) {
        headline = 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_SERVICE';
      } else if (this._closedByBuyer) {
        headline = 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER';
      } else if (this._closedByBuyerTimeout) {
        headline = 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER_TIMEOUT';
      } else if (this._shopTimeout && type === 'BUYER') {
        headline = await new Promise(resolve => {
          this._translateService.get('MESSAGE_STREAM.NO_ANSWER_SHOP_HEADLINE', {shop: this._protectionInfo.shopInfo.shop.name})
            .subscribe(value => {
              resolve(value);
            });
        }) as string;

        messageItems.push({
          headline: 'MESSAGE_STREAM.NO_ANSWER_SHOP_PROBLEM_SOLVED',
          sender: 'CONSUMER',
          initiallyOpen: true,
          deliveryDate: this._declaration.actualDeliveryDate,
          showBorder: true,
          declarationUuid: this._declaration.declarationUuid
        });
        headline = 'MESSAGE_STREAM.PROTECTION_CLOSED';
      }

      messageItems.push({
        headline,
        sender: this._declaration.refundReceived ? 'CONSUMER' : 'EMPLOYEE',
        initiallyOpen: true,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid,
        userEmail: this._protectionInfo.buyerInfo.consumerEmail,
        refundAmount: this._declaration.amountRefund,
        currency: this._declaration.currency
      });
    }

    if ((this._declaration.state === DeclarationState.CLOSED || this._declaration.state === DeclarationState.DECISION_MADE) &&
      this._declaration.decision) {
      let headline: string;
      let message = this._declaration.decision.reason;

      if (!this._declaration.decision.reasonable && !this._declaration.decision.goodwill) {

        /* ***************************************** */
        /* Protection closed with decision no refund */
        /* ***************************************** */

        headline = type === 'BUYER' ? 'MESSAGE_STREAM.TS_DECISION_NO_REFUND_BUYER' : 'MESSAGE_STREAM.TS_DECISION_NO_REFUND_SHOP';
      } else if (this._declaration.decision.reasonable && !this._declaration.decision.goodwill) {

        /* ***************************************** */
        /* Protection closed with decision refund */
        /* ***************************************** */

        headline = type === 'BUYER' ? 'MESSAGE_STREAM.TS_DECISION_REFUND_BUYER' : 'MESSAGE_STREAM.TS_DECISION_REFUND_SHOP';
      } else if (!this._declaration.decision.reasonable && this._declaration.decision.goodwill) {

        /* *********************************************** */
        /* Protection closed with decision goodwill refund */
        /* *********************************************** */
        headline =
          type === 'BUYER' ? 'MESSAGE_STREAM.TS_DECISION_GOODWILL_REFUND_BUYER' : 'MESSAGE_STREAM.TS_DECISION_NO_REFUND_SHOP';
        if (type === 'SHOP') {
          message = '';
        }
      }

      messageItems.push({
        headline,
        message,
        translateMessage: false,
        sender: 'EMPLOYEE',
        initiallyOpen: true,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid,
        refundAmount: this._declaration.decision.amountRefund,
        currency: this._declaration.currency
      });

      if (this._declaration.state === DeclarationState.CLOSED) {
        messageItems.push({
          headline: this._closedByBuyer ? 'MESSAGE_STREAM.PROTECTION_CLOSED_BY_BUYER' : 'MESSAGE_STREAM.PROTECTION_CLOSED',
          sender: 'EMPLOYEE',
          initiallyOpen: true,
          deliveryDate: this._declaration.actualDeliveryDate,
          showBorder: true,
          declarationUuid: this._declaration.declarationUuid,
          userEmail: this._protectionInfo.buyerInfo.consumerEmail,
          refundAmount: this._declaration.amountRefund,
          currency: this._declaration.currency
        });
      }
    }

    if (type === 'BUYER' && this._shopTimeout &&
      this._declaration.state !== DeclarationState.CONSUMER_CONFIRM &&
      this._declaration.state !== DeclarationState.DECLARED &&
      this._declaration.state !== DeclarationState.DECLARING &&
      this._messages.filter(message => message.senderRole === 'CONSUMER').length > 1) {
        messageItems[2].headline = 'MESSAGE_STREAM.NO_ANSWER_SHOP_PROBLEM_NOT_SOLVED';
        messageItems[2].initiallyOpen = true;
    }

    if ((this._declaration.state === DeclarationState.TS_CHECK ||
      this._declaration.state === DeclarationState.CLOSED ||
      this._declaration.state === DeclarationState.WAITING_FOR_BANKACCOUNT_INFO ||
      this._declaration.state === DeclarationState.DECISION_MADE) &&
      this._shopTimeout) {
      const noAnswerMessage = messageItems.splice(1, 1);
      if (type === 'SHOP' && this._declaration.state === DeclarationState.TS_CHECK) {
        messageItems.push(noAnswerMessage[0]);
      } else {
        messageItems.splice(messageItems.length - 1, 0, noAnswerMessage[0]);
      }

      [messageItems[1], messageItems[2]] = [messageItems[2], messageItems[1]];
    }

    messageItems[0].showBorder = false;
    messageItems[messageItems.length - 1].initiallyOpen = true;
    for (let i = 0; i < messageItems.length; i ++) {
      if (!messageItems[i].headline) {
        messageItems[i].initiallyOpen = true;
        messageItems[i].hideToggle = true;
      }
    }

    return this._addDocuments(messageItems).reverse();
  }

  async assignUser(assignmentOpts: { fromForm: boolean, callback?: (result: boolean) => void }) {
    if (this._tryToAssign || !assignmentOpts.fromForm) {
      this._tryToAssign = false;
      this._declaration = await this._declarationService.getDeclaration(this._declarationUuid);
      if (!this._declaration.assignee || this._declaration.assignee.uuid !== this._userService.employee_uuid.value) {
        try {
          this._declaration = await this._declarationService.claimDeclaration(this._declarationUuid);
          if (assignmentOpts.callback) {
            assignmentOpts.callback(this._declaration.assignee.uuid === this._userService.employee_uuid.value);
          }
          this._protectionInfo.protectionInfo.assignee = `${this._declaration.assignee.firstname} ${this._declaration.assignee.lastname}`;
          this._assigneeUuid = this._userService.employee_uuid.value;
          this._showAssignment = true;
          setTimeout(() => this._showAssignment = false, 10000);
        } catch {
          this._assignError = true;
          this._showAssignment = true;
          setTimeout(() => {
            this._showAssignment = false;
            this._assignError = false;
          }, 10000);
        }
      } else {
        const assignee = `${this._declaration.assignee.firstname} ${this._declaration.assignee.lastname}`;
        this._protectionInfo.protectionInfo.assignee = assignee;
        this._assigneeUuid = this._userService.employee_uuid.value;
        const currentState = this._declarationService.controlCommitButton.value;
        this._declarationService.controlCommitButton.next(Object.assign(
          {},
          currentState,
          {assignedToOtherUser: assignee} as IControlCommitButton
        ));
      }
    }
  }

  onAnimationEvent(event: AnimationEvent) {
    if (event['phaseName'] === 'start' || this._showAssignment) {
      this._blockMessage = true;
    } else {
      this._blockMessage = false;
    }
  }

  private _addDocuments(messageItems: Array<IMessageStreamItem>): Array<IMessageStreamItem> {
    for (const message of messageItems) {
      if (message.messageUuid) {
        const filteredDocuments = this._documents.filter(document => document.attachedToMessages.includes(message.messageUuid));
        if (filteredDocuments.length > 0) {
          message.documents = filteredDocuments.map(document => {
            return { document } as IDocumentDisplayData;
          });
        }
      }
    }
    return messageItems;
  }

  async createDocumentsMessage() {
    const controlCommitButton = this._declarationService.controlCommitButton.value;
    const consumerMessage = await this._declarationService.createMessageForDeclaration(this._declarationUuid, {
      senderRole: 'EMPLOYEE',
      recipientRole: 'CONSUMER',
      message: 'DOCUMENT_MESSAGE'
    });

    const uploadedDocuments = controlCommitButton.attachToConsumerDocumentMessage
      .filter(x => x.action === 'UPLOAD')
      .map(x => x.uuid);
    if (uploadedDocuments.length > 0) {
      await this._declarationService.attachDocumentToMessage(this._declarationUuid,
        consumerMessage.uuid,
        uploadedDocuments,
        'UPLOAD');
    }

    const sharedDocuments = controlCommitButton.attachToConsumerDocumentMessage
      .filter(x => x.action === 'SHARED')
      .map(x => x.uuid);
    if (sharedDocuments.length > 0) {
      await this._declarationService.attachDocumentToMessage(this._declarationUuid, consumerMessage.uuid, sharedDocuments, 'SHARED');
    }
  }

  async closeDeclaration() {
    try {
      await this.assignUser({fromForm: true});
      const closed = await this._declarationService.closeDeclaration(this._declarationUuid);
      if (closed) {
        this._loadData();
      }
    } catch {
      this.errorModal.openModal();
    }
  }

  private async _loadHistory() {
    try {
      this._isLoading = true;
      this._actionsList = await this._declarationService.getProtectionHistory(this._protectionInfo.protectionInfo.protectionNumber);
      this._actionsList.sort((a, b) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
      });
      for (const action of this._actionsList) {
        if (action.internalComment && action.actionKey.indexOf('declaration') === 0) {
          action.message = await this._declarationService.getMessagesByMessageId(this._declarationUuid, action.internalComment);
        }
      }
      this._closedByService = this._actionsList.some(e => e.actionKey === 'declaration.action.ts.close_declaration');
      this._closedByBuyer = this._actionsList.some(e => e.actionKey === 'declaration.action.consumer.cancel_declaration');
      this._closedByBuyerTimeout = this._actionsList.some(e => e.actionKey === 'declaration.action.consumer.buyer.timeout');
      this._shopTimeout = this._actionsList.some(e => e.actionKey === 'declaration.action.consumer.shop.timeout');
      this._isLoading = false;
    } catch (e) {
      this._isLoading = false;
    }
  }

  scrollToHistory() {
    const el = this._window.document.querySelector('div.card.protection-history');
    el.scrollIntoView({behavior: 'smooth'});
    this._scrolled.emit();
  }
}
