import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IMessageStreamItem } from '../../../../core/models/messageStreamItem.interface';
import { IDeclaration } from '../../../../core/models/declaration.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DeclarationService } from '../../../../core/services/declaration.service';
import { IControlCommitButton } from '../../../../core/models/control-commit-button.interface';
import { UserService } from '../../../../../../core/services/user.service';
import { IProtectionInfo } from '../../../../core/models/protectionInfo.interface';
import { TranslateService } from '@ngx-translate/core';
import { DeclarationState } from '../../../../core/models/declarationState.enum';
import { IBankAccountData } from '../../../../core/models/bankAccountData.interface';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-buyer-info',
  templateUrl: './buyer-info.component.html',
  styleUrls: ['../info-cards.component.scss'],
  animations: [
    trigger('open', [
      state('closed', style({
        height: 0,
      })),
      state('open', style({
        height: '*',
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ])
  ]
})
export class BuyerInfoComponent implements OnInit, AfterViewInit, OnChanges {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  private readonly _userService: UserService;
  private readonly _translateService: TranslateService;
  //#endregion

  //#region Properties
  private _messages: IMessageStreamItem[];
  public get messages(): IMessageStreamItem[] {
    return this._messages;
  }
  @Input() public set messages(v: IMessageStreamItem[]) {
    this._messages = v;
  }
  private _message = '';
  public get message(): string {
    return this._message;
  }

  private _assignementNeeded = new EventEmitter<{ fromForm: boolean; callback?: (result: boolean) => void }>();
  @Output() public get assignementNeeded(): EventEmitter<{ fromForm: boolean; callback?: (result: boolean) => void }> {
    return this._assignementNeeded;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }
  @Input() public set type(v: string) {
    this._type = v;
  }

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
  }

  private _infos: IProtectionInfo;
  @Input() public set infos(v: IProtectionInfo) {
    this._infos = v;
  }

  private _dummyFormMessageStreamItem: IMessageStreamItem;
  public get dummyFormMessageStreamItem(): IMessageStreamItem {
    return this._dummyFormMessageStreamItem;
  }

  private _action = '0';
  public get action(): string {
    return this._action;
  }

  private _messageInputOpen = 'closed';
  public get messageInputOpen(): string {
    return this._messageInputOpen;
  }

  private _isInitializing = true;
  public get isInitializing(): boolean {
    return this._isInitializing;
  }

  private _assignee = '';
  public get assignee(): string {
    return this._assignee;
  }

  private _showWarning = false;
  public get showWarning(): boolean {
    return this._showWarning;
  }

  private _canEdit = false;
  public get canEdit(): boolean {
    return this._canEdit;
  }

  private _showUpload = true;
  public get showUpload(): boolean {
    return this._showUpload;
  }

  private _showAmountInput = false;
  public get showAmountInput(): boolean {
    return this._showAmountInput;
  }

  private _refundAmount = '';
  public get refundAmount(): string {
    return this._refundAmount;
  }

  private _includeServiceFee = true;
  public get includeServiceFee(): boolean {
    return this._includeServiceFee;
  }

  private _bankAccountData: IBankAccountData;
  public get bankAccountData(): IBankAccountData {
    return this._bankAccountData;
  }

  private _showBankAccountForm = new BehaviorSubject<boolean>(false);
  public get showBankAccountForm(): BehaviorSubject<boolean> {
    return this._showBankAccountForm;
  }

  private _isServiceUser = false;
  public get isServiceUser(): boolean {
    return this._isServiceUser;
  }
  //#endregion

  constructor(declarationService: DeclarationService, userService: UserService, translateService: TranslateService) {
    this._declarationService = declarationService;
    this._userService = userService;
    this._translateService = translateService;
  }

  ngOnInit() {
    this._declarationService.controlCommitButton.subscribe(next => {

      if (this._type === 'SHOP' && !next.buttonShownForShop || this._type === 'BUYER' && !next.buttonShownForBuyer) {
        this._messageInputOpen = 'closed';
        this._action = '0';
        this._message = '';
      }

      if (next.assignedToOtherUser !== '' && this._messageInputOpen === 'open') {
        this._assignee = next.assignedToOtherUser;
        this._showWarning = true;
        this._messageInputOpen = 'closed';

        this._declarationService.controlCommitButton.next({
          buttonShownForShop: false,
          buttonShownForBuyer: false,
          attachToShopDocumentMessage: [],
          attachToConsumerDocumentMessage: [],
          assignedToOtherUser: '',
          commitTypeShop: '',
          commitTypeBuyer: ''
        });
      }
    });

    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isServiceUser = roles.includes('ts_service') || roles.includes('myts_ts_service');
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this._isInitializing = false, 2000);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this._declaration) {
      this._canEdit = !this._declaration.assignee || this._userService.employee_uuid.value === this._declaration.assignee.uuid;
      if (this._declaration.state === DeclarationState.DECLARED && this._type === 'SHOP' && this._messages.length > 0) {
        this._messages[this._messages.length - 1].initiallyOpen = false;
        const headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.REFUND_QUESTION').subscribe(next => {
            resolve(next as string);
          });
        });

        this._dummyFormMessageStreamItem = {
          sender: 'SHOP',
          headline: headline,
          translateHeadline: true,
          initiallyOpen: true,
          showBorder: true,
          shopName: this._infos.shopInfo.shop.url
        };
      } else if (this._declaration.state === DeclarationState.SHOP_ANSWERED &&
        this._declaration.feedbackShop !== 'NON_REFUND' &&
        this._type === 'BUYER' && this._messages.length > 0) {
        let headlineKey = 'MESSAGE_STREAM.PARTIAL_REFUND_RECEIVED_REQUEST';
        if (this._declaration.feedbackShop === 'REFUND' || this._declaration.feedbackShop === 'REFUND_LATER') {
          headlineKey = 'MESSAGE_STREAM.REFUND_RECEIVED_REQUEST';
        }
        const headline = await new Promise<string>(resolve => {
          this._translateService.get(headlineKey,
            {shopName: this._infos.shopInfo.shop.name}).subscribe(next => {
            resolve(next);
          });
        });

        this._dummyFormMessageStreamItem = {
          sender: 'CONSUMER',
          headline,
          translateHeadline: true,
          message: this._declaration.feedbackShop,
          initiallyOpen: true,
          showBorder: true,
          userEmail: this._infos.buyerInfo.consumerEmail
        };
      } else if (this._declaration.state === DeclarationState.SHOP_ANSWERED &&
        this._declaration.feedbackShop === 'NON_REFUND' &&
        this._type === 'BUYER' && this._messages.length > 0) {
        this._dummyFormMessageStreamItem = {
          sender: 'CONSUMER',
          initiallyOpen: true,
          showBorder: true,
          userEmail: this._infos.buyerInfo.consumerEmail
        };
      } else if (this._declaration.state === DeclarationState.FEEDBACK_NEEDED_SHOP && this._type === 'SHOP' ||
                  this._declaration.state === DeclarationState.FEEDBACK_NEEDED_CONSUMER && this._type === 'BUYER' ||
                  this._declaration.state === DeclarationState.FEEDBACK_NEEDED_CONSUMER_AND_SHOP) {
        const headline = await new Promise<string>(resolve => {
            this._translateService.get('MESSAGE_STREAM.MESSAGE_TO_TS').subscribe(next => {
              resolve(next);
            });
          });

        this._dummyFormMessageStreamItem = {
          sender: this._type === 'BUYER' ? 'CONSUMER' : 'SHOP',
          headline: headline,
          translateHeadline: true,
          message: this._type,
          initiallyOpen: true,
          showBorder: true,
          userEmail: this._type === 'BUYER' ? this._infos.buyerInfo.consumerEmail : null,
          shopName: this._type === 'SHOP' ? this._infos.shopInfo.shop.url : null
        };
      } else if (this._declaration.state === DeclarationState.WAITING_FOR_BANKACCOUNT_INFO && this._type === 'BUYER') {
        this._dummyFormMessageStreamItem = {
          sender: 'CONSUMER',
          headline: this._declaration.decision.goodwill ? 'MESSAGE_STREAM.TS_DECISION_GOODWILL_REFUND_BUYER' :
            'MESSAGE_STREAM.TS_DECISION_REFUND_BUYER',
          translateHeadline: false,
          message: this._declaration.decision.reason,
          initiallyOpen: true,
          showBorder: true,
          refundAmount: this._declaration.decision.amountRefund,
          currency: this._infos.protectionInfo.orderCurrency,
          userEmail: this._infos.buyerInfo.consumerEmail
        };
      } else if (this._declaration.state === DeclarationState.CONSUMER_CONFIRM && this._type === 'BUYER') {
        this._dummyFormMessageStreamItem = {
          sender: 'CONSUMER',
          initiallyOpen: true,
          showBorder: true,
          userEmail: this._infos.buyerInfo.consumerEmail
        };
      }
    }
  }

  async chooseAction(event) {
    this._showUpload = true;
    const currentState = this._declarationService.controlCommitButton.value;
    if (event.target.value === '0') {
      this._messageInputOpen = 'closed';
      delete currentState.includeServiceFee;

      if (this._type === 'SHOP') {
        this._declarationService.controlCommitButton.next(Object.assign(
          {},
          currentState,
          {buttonShownForShop: false, commitTypeShop: ''} as IControlCommitButton
        ));
      } else {
        this._declarationService.controlCommitButton.next(Object.assign(
          {},
          currentState,
          {buttonShownForBuyer: false, commitTypeBuyer: ''} as IControlCommitButton
        ));
      }
      return;
    }
    this._action = event.target.value;
    if (event.target.value === '1') {
      this._messageInputOpen = 'open';
      // this._showUpload = false;
      this._showAmountInput = false;

      this._declarationService.controlCommitButton.next({
        buttonShownForShop: false,
        buttonShownForBuyer: true,
        attachToShopDocumentMessage: [],
        attachToConsumerDocumentMessage: [],
        assignedToOtherUser: '',
        commitTypeBuyer: 'DECISION_NOT_REASONABLE',
        commitTypeShop: ''
      });
    } else if (event.target.value === '4') {
      if (!this._bankAccountData) {
        try {
          this._bankAccountData = await this._declarationService.getBankAccount(this._declaration.declarationUuid);
          this._bankAccountData.available = !!this._bankAccountData.firstname;
        } catch {
          this._bankAccountData = {
            available: false
          };
        }
      }
      this._refundAmount = this._infos.protectionInfo.protectedAmount >= this._infos.protectionInfo.orderAmount ?
        formatNumber(this._infos.protectionInfo.orderAmount, this._translateService.currentLang, '1.2-2') :
        formatNumber(this._infos.protectionInfo.protectedAmount, this._translateService.currentLang, '1.2-2');
      this._messageInputOpen = 'open';
      // this._showUpload = false;
      this._showAmountInput = true;

      this._declarationService.controlCommitButton.next({
        buttonShownForShop: false,
        buttonShownForBuyer: true,
        attachToShopDocumentMessage: [],
        attachToConsumerDocumentMessage: [],
        assignedToOtherUser: '',
        commitTypeBuyer: 'DECISION_REASONABLE',
        commitTypeShop: '',
        refundAmount: this._infos.protectionInfo.protectedAmount >= this._infos.protectionInfo.orderAmount ?
          this._infos.protectionInfo.orderAmount : this._infos.protectionInfo.protectedAmount,
        includeServiceFee: this._includeServiceFee
      });
    } else if (event.target.value === '5') {
      if (!this._bankAccountData) {
        try {
          this._bankAccountData = await this._declarationService.getBankAccount(this._declaration.declarationUuid);
          this._bankAccountData.available = !!this._bankAccountData.firstname;
        } catch {
          this._bankAccountData = {
            available: false
          };
        }
      }
      this._refundAmount = this._infos.protectionInfo.protectedAmount >= this._infos.protectionInfo.orderAmount ?
        formatNumber(this._infos.protectionInfo.orderAmount, this._translateService.currentLang, '1.2-2') :
        formatNumber(this._infos.protectionInfo.protectedAmount, this._translateService.currentLang, '1.2-2');
      this._messageInputOpen = 'open';
      // this._showUpload = false;
      this._showAmountInput = true;

      this._declarationService.controlCommitButton.next({
        buttonShownForShop: false,
        buttonShownForBuyer: true,
        attachToShopDocumentMessage: [],
        attachToConsumerDocumentMessage: [],
        assignedToOtherUser: '',
        commitTypeBuyer: 'DECISION_GOODWILL',
        commitTypeShop: '',
        refundAmount: this._infos.protectionInfo.protectedAmount >= this._infos.protectionInfo.orderAmount ?
          this._infos.protectionInfo.orderAmount : this._infos.protectionInfo.protectedAmount
      });
    } else {
      this._messageInputOpen = 'open';
      this._showAmountInput = false;

      if (this._type === 'SHOP') {
        if (currentState.commitTypeBuyer === 'DECISION_NOT_REASONABLE') {
          this._declarationService.controlCommitButton.next(Object.assign(
            {},
            currentState,
            {
              buttonShownForBuyer: false,
              buttonShownForShop: true,
              commitTypeBuyer: '',
              commitTypeShop: event.target.value === '2' ? 'DOCUMENT_QUERY' : 'SIMPLE_MESSAGE'
            } as IControlCommitButton
          ));
        } else {
          this._declarationService.controlCommitButton.next(Object.assign(
            {},
            currentState,
            {
              buttonShownForShop: true,
              commitTypeShop: event.target.value === '2' ? 'DOCUMENT_QUERY' : 'SIMPLE_MESSAGE'
            } as IControlCommitButton
          ));
        }
      } else {
        delete currentState.includeServiceFee;
        this._declarationService.controlCommitButton.next(Object.assign(
          {},
          currentState,
          {
            buttonShownForBuyer: true,
            commitTypeBuyer: event.target.value === '2' ? 'DOCUMENT_QUERY' : 'SIMPLE_MESSAGE'
          } as IControlCommitButton
        ));
      }
    }
  }

  changeMessage(event) {
    this._assignementNeeded.emit({ fromForm: true });
    this._message = event.target.value;
    const currentState = this._declarationService.controlCommitButton.value;
    if (this._type === 'SHOP' && (currentState.commitTypeShop === 'DOCUMENT_QUERY' || currentState.commitTypeShop === 'SIMPLE_MESSAGE')) {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {shopDocumentMessage: event.target.value} as IControlCommitButton
      ));
    } else if (this._type === 'BUYER' &&
      (currentState.commitTypeBuyer === 'DOCUMENT_QUERY' || currentState.commitTypeBuyer === 'SIMPLE_MESSAGE')) {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {consumerDocumentMessage: event.target.value} as IControlCommitButton
      ));
    } else if (this._type === 'BUYER' &&
      (currentState.commitTypeBuyer === 'DECISION_NOT_REASONABLE' ||
        currentState.commitTypeBuyer === 'DECISION_REASONABLE' ||
        currentState.commitTypeBuyer === 'DECISION_GOODWILL')) {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {decisionMessage: event.target.value} as IControlCommitButton
      ));
    }
  }

  changeAmount(event) {
    this._assignementNeeded.emit({ fromForm: true });
    this._refundAmount = event.target.value;
    let controlValue = this._refundAmount;
    controlValue = controlValue.replace(' ', '');
    if (/,\d{1,2}$/.test(controlValue)) {
      controlValue = controlValue.replace(/(,)(\d{1,2})$/, '.$2');
    }
    const currentState = this._declarationService.controlCommitButton.value;
    if (isNaN(parseFloat(controlValue))) {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {refundAmount: 0} as IControlCommitButton
      ));
    } else {
      this._declarationService.controlCommitButton.next(Object.assign(
        {},
        currentState,
        {refundAmount: parseFloat(controlValue)} as IControlCommitButton
      ));
    }
  }

  changeServiceFee() {
    this._includeServiceFee = !this._includeServiceFee;
    const currentState = this._declarationService.controlCommitButton.value;
    this._declarationService.controlCommitButton.next(Object.assign(
      {},
      currentState,
      {includeServiceFee: this._includeServiceFee}
    ) as IControlCommitButton);
  }

  assignForUpload() {
    this._assignementNeeded.emit({ fromForm: true });
  }

  provideBankData() {
    this._assignementNeeded.emit({ fromForm: true });
    this._showBankAccountForm.next(true);
  }

  bankAccountSaved(bankAccount: IBankAccountData) {
    this._bankAccountData = bankAccount;
  }
}
