import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'loading-overlay',
  styleUrls: ['loading-overlay.component.scss'],
  templateUrl: 'loading-overlay.component.html'
})
export class LoadingOverlayComponent {
  @Input() fixedPosition: boolean;
}
