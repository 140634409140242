import {Veto} from './veto';
import {Review, ReviewState} from '../../schemas/review';
import {Injectable} from '@angular/core';

@Injectable()

export class ApproveMutation extends Veto {
  public async apply(review: Review): Promise<ReviewState> {
    if (![ReviewState.REJECTED, ReviewState.MODERATION, ReviewState.SUBMITTED].includes(review.state)) {
      throw new Error('Only review state REJECTED, MODERATION or SUBMITTED can be APPROVED');
    }

    return await this.changeReviewState(review.id, ReviewState.APPROVED);
  }
}
