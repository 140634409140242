import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewSearchFormService {
  private searchTriggerSubject = new Subject<void>();

  searchTrigger$ = this.searchTriggerSubject.asObservable();

  triggerSearch() {
    this.searchTriggerSubject.next();
  }
}
