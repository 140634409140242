import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _selectedImage = new BehaviorSubject(null);
  private _availableImages = new BehaviorSubject(null);

  public openImageModal(imageIndex: number, images: string[]) {
    this._availableImages.next(images);
    this.setSelectedImageIndex(imageIndex);
  }

  public setSelectedImageIndex(imageIndex: number) {
    if (imageIndex < 0 || imageIndex >= this._availableImages.value.length) {
      this.closeImageModal();
      return;
    }
    this._selectedImage.next(this._availableImages.value[imageIndex]);
  }

  public closeImageModal() {
    this._selectedImage.next(null);
    this._availableImages.next(null);
  }

  public getAvailableImages() {
    return this._availableImages.asObservable();
  }

  public getCurrentImage() {
    return this._selectedImage.asObservable();
  }
}
