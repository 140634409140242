import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { ProtectionsUIModule } from '../ui/protections-ui.module';
import { DeclarationService } from './services/declaration.service';
import { SearchFilterService } from './services/search-filter.service';
import { IbanValidatorService } from './services/iban-validator.service';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [],
  exports: [
    ProtectionsUIModule
  ],
  providers: [
    DeclarationService,
    SearchFilterService,
    IbanValidatorService
  ]
})
export class ProtectionsModule { }
