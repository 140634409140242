export class IbanValidatorService {
  //#region Private Fields
  private readonly _ibanLengths = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
  };
  //#endregion

  //#region Public Methods
  public checkIbanValidity(input: string): boolean {
    const iban = input.toUpperCase().replace(/ /g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    if (!code) {
      return false;
    }

    const country = code[1];
    const checksumDigits = code[2];
    const bankAccountIdentifier = code[3];

    if (!code || iban.length !== this._ibanLengths[country]) {
        return false;
    }

    const digits = (bankAccountIdentifier + country + checksumDigits)
        .replace(/[A-Z]/g, (letter) => (letter.charCodeAt(0) - 55).toString());

    return this.validateChecksum(digits);
  }
  //#endregion


  //#region Private Methods

  private validateChecksum(parsedIban: string): boolean {
    let checksum = parseInt(parsedIban.slice(0, 2), undefined);
    let fragment: string;

    for (let offset = 2; offset < parsedIban.length; offset += 7) {
      fragment = String(checksum) + parsedIban.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum === 1 ? true : false;
  }
  //#endregion
}
