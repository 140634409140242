import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../services/modal/modal.service';

@Component({
  selector: 'app-image-modal-gallery',
  templateUrl: './image-modal-gallery.component.html',
  styleUrls: ['./image-modal-gallery.component.scss']
})
export class ImageModalGalleryComponent implements OnInit, OnDestroy {
  currentImage: string | null;
  availableImages: string[] | null;
  currentImageSub: Subscription;
  availableImagesSub: Subscription;

  constructor(private modalService: ModalService) {
    this.currentImageSub = this.modalService.getCurrentImage().subscribe(image => this.currentImage = image);
    this.availableImagesSub = this.modalService.getAvailableImages().subscribe(images => this.availableImages = images);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.currentImageSub.unsubscribe();
    this.availableImagesSub.unsubscribe();
  }

  closeModal() {
    this.modalService.closeImageModal();
  }

  onImageClick(selectedImage: string) {
    this.modalService.setSelectedImageIndex(this.availableImages.indexOf(selectedImage));
  }

  getPlaceholdersBefore(): number[] {
    const currentIdx = this.availableImages.indexOf(this.currentImage);
    const beforeImages = 2 - currentIdx;
    if (beforeImages > 0) {
      return Array(beforeImages).fill(0);
    }
    return [];
  }

  getPlaceholdersAfter(): number[] {
    const currentIdx = this.availableImages.indexOf(this.currentImage);
    const leftoverImages = this.availableImages.length - 1 - currentIdx;
    if (leftoverImages > 2 || leftoverImages < 0) {
      return [];
    }
    return Array(2 - leftoverImages).fill(0);
  }

  navigateImageCarrousel(direction: number) {
    let newIndex = this.availableImages.indexOf(this.currentImage) + direction;
    if (newIndex > this.availableImages.length - 1) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = this.availableImages.length - 1;
    }
    this.modalService.setSelectedImageIndex(newIndex);
  }

  getCarrouselImages(): string[] {
    const OFFSET = 2;
    const currentIdx = this.availableImages.indexOf(this.currentImage);
    let start = currentIdx - OFFSET;
    let end = currentIdx + OFFSET;

    start = start < 0 ? 0 : start;
    end = end > this.availableImages.length - 1 ? this.availableImages.length - 1 : end;

    return this.availableImages.slice(start, end + 1);
  }
}
