import { Component, Input, OnInit } from '@angular/core';
import { IDeclarationMessage } from '../../core/models/declarationMessage.interface';

@Component({
  selector: 'app-comment-stream-item',
  templateUrl: './comment-stream-item.component.html',
  styleUrls: ['./comment-stream-item.component.scss']
})
export class CommentStreamItemComponent {
  //#region Properties
  private _comment: IDeclarationMessage;
  public get comment(): IDeclarationMessage {
    return this._comment;
  }
  @Input() public set comment(v: IDeclarationMessage) {
    this._comment = v;
    let tempMessage = '';
    for (const chunk of this._comment.message.split(' ')) {
      if (`${tempMessage} ${chunk}`.length >= 200) {
        this._shortenedMessage = `${tempMessage} ...`;
        break;
      }
      tempMessage = tempMessage.length > 0 ? `${tempMessage} ${chunk}` : chunk;
      this._shortenedMessage = tempMessage;
    }
  }

  private _shortenedMessage: string;
  public get shortenedMessage(): string {
    return this._shortenedMessage;
  }

  private _wholeMessageShown = false;
  public get wholeMessageShown(): boolean {
    return this._wholeMessageShown;
  }
  //#endregion

  showWholeMessage(e) {
    e.preventDefault();
    this._wholeMessageShown = true;
  }
}
