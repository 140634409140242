import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { TriggerDaysButtonComponent } from './views/trigger-days-button/trigger-days-button.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TriggerDaysButtonComponent
  ],
  exports: [
    TriggerDaysButtonComponent
  ],
  providers: []
})
export class PaymentEscalatedUIModule { }
