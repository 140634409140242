import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PaymentEscalatedService } from '../../../core/services/payment-escalated.service';

@Component({
  selector: 'app-trigger-days-button',
  templateUrl: './trigger-days-button.component.html',
  styleUrls: ['./trigger-days-button.component.scss']
})
export class TriggerDaysButtonComponent {
  @Input() protectionUuid: string;
  triggerDays = new FormControl('', [Validators.required, Validators.pattern(/\b([1-9]|[1-9][0-9])\b/)]);
  error = false;
  sent = false;

  constructor(private paymentEscalatedService: PaymentEscalatedService) {}

  async sendEvent() {
    try {
      await this.paymentEscalatedService.sendPEEvent(this.triggerDays.value, this.protectionUuid);
      this.sent = true;
    } catch {
      this.error = true;
    }
  }
}
