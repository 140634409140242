import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(translate: TranslateService, userService: UserService) {
    translate.setDefaultLang('de');
    translate.use('de');
    userService.locale.subscribe(
      locale => {
        if (locale === undefined) {
          translate.use('de');
        } else {
          translate.use(locale);
        }
      }
    );
  }
}
