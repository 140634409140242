import { Review, ReviewState } from '../../schemas/review';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../api-key/api-key.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApproveSubmittedReviewsRequestBody, ApproveSubmittedReviewsResponse } from '../../schemas/api';

@Injectable({
  providedIn: 'root'
})
export abstract class Veto {

  private payload = (state) => {
    return [
      {
        op: 'replace',
        path: '/state',
        value: {
          state
        }
      }
    ];
  }

  public constructor(
    private apiKeyService: ApiKeyService,
    private http: HttpClient
  ) { }

  public abstract apply(review: Review): Promise<ReviewState>;

  async changeReviewState(id: string, state: ReviewState): Promise<ReviewState> {
    const url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/${id}`;
    const apiKey = this.apiKeyService.reviewServiceApiKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    try {
      return await this.http.patch<any>(url, this.payload(state), options)
        .pipe(
          map(data => {
            return data.state;
          })
        )
        .toPromise();
    } catch (error) {
      console.error(error.message);
      const message = 'Sorry it\'s not you it\'s me. Please try again.';
      throw new Error(message);
    }
  }

  async approveSubmittedReviews(channelId: string, reviewIds: string[]): Promise<ApproveSubmittedReviewsResponse | null> {
    const url = `${environment.reviews.apiBaseUrl}/reviews-zendesk/channels/${channelId}/approved`;
    const apiKey = this.apiKeyService.reviewServiceApiKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    const requestBody: ApproveSubmittedReviewsRequestBody = {
      reviewIds
    };

    try {
      const response = await this.http.post<ApproveSubmittedReviewsResponse>(url, requestBody, options).toPromise();
      return response || null;
    } catch (error) {
      console.error(error.message);
      const message = 'Sorry it\'s not you it\'s me. Please try again.';
      throw new Error(message);
    }
  }
}
