import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class ApiKeyService {

  private readonly _document: Document;
  public readonly REVIEW_API_KEY = 'review-api-key';

  public constructor(@Inject(DOCUMENT) document: Document) {
    this._document = document;
  }

  public set(apiKey: string) {
     this._document.defaultView.localStorage.setItem(this.REVIEW_API_KEY, apiKey);
  }

  public get() {
    return this._document.defaultView.localStorage.getItem(this.REVIEW_API_KEY);
  }

  public channelMapperAPIKey() {
    const apiKeys = this.splitApiKey();

    return apiKeys[0] || null;

  }

  public reviewServiceApiKey() {
    const apiKeys = this.splitApiKey();

    return apiKeys[1] || null;
  }

  private splitApiKey() {
    try {
      const apiKey = this.get();
      return apiKey.split('----');
    } catch (error) {
      return [];
    }
  }

}


