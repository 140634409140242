import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';

const ATTACHMENT_PATH = environment.attachmentBaseUrl;

@Pipe({ name: 'imagePath' })
export class ImagePathPipe implements PipeTransform {
  transform(relativeImagePath: string): string {
      return ATTACHMENT_PATH + relativeImagePath;
  }
}
