import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { IBankAccountData } from '../../../core/models/bankAccountData.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { bankAccountCountries } from '../../configuration/bank-account-countries';
import { ICountry } from '../../../core/models/country.interface';
import { ibanValidator } from '../../../core/validators/iban-validator.directive';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['./bank-account-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankAccountFormComponent implements OnInit {
  //#region Properties
  private readonly _declarationService: DeclarationService;
  private readonly _translateService: TranslateService;
  //#endregion

  //#region Properties
  @ViewChild(ModalDirective) bankAccountModal: ModalDirective;

  @Output()
  public bankAccountSaved = new EventEmitter<IBankAccountData>();

  private _declarationUuid: string;
  @Input() public set declarationUuid(v: string) {
    this._declarationUuid = v;
  }

  private _showEvent: BehaviorSubject<boolean>;
  @Input() public set showEvent(v: BehaviorSubject<boolean>) {
    v.subscribe(next => {
      if (next) {
        this.bankAccountModal.show();
      }
    });
    this._showEvent = v;
  }

  bankAccountForm = this.fb.group({
    iban: ['', ibanValidator()],
    // tslint:disable-next-line:max-line-length
    bic: ['', Validators.pattern(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/)],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    street: ['', Validators.required],
    number: ['', Validators.required],
    zipCode: ['', Validators.required],
    town: ['', Validators.required],
    countryId: ['57']
  });

  private _countryList: Array<ICountry> = [];
  public get countryList(): Array<ICountry> {
    return this._countryList;
  }
  //#endregion

  constructor(declarationService: DeclarationService, private fb: FormBuilder, translateService: TranslateService) {
    this._declarationService = declarationService;
    this._translateService = translateService;
  }

  ngOnInit() {
    const countries = bankAccountCountries.countries;
    const iso3Array = Object.keys(countries);
    this._translateService.get(iso3Array).subscribe(res => {
      this._countryList = Object.keys(countries)
        .map(key => Object.assign(countries[key], res[key] ? {translatedName: res[key]} : {translatedName: ''}));
      this._countryList.sort((a, b) => (a.order < 0 || b.order < 0) ? a.order - b.order : a.translatedName.localeCompare(b.translatedName));
    });
  }

  async onSubmit() {
    const account = await this._declarationService.setBankAccount(this._declarationUuid, this.bankAccountForm.value as IBankAccountData);
    this.bankAccountSaved.emit(Object.assign({}, account, {available: true}) as IBankAccountData);
    this.bankAccountModal.hide();
  }
}
