import { Component, Input } from '@angular/core';
import { ModalService } from '../services/modal/modal.service';
import { Review, ReviewState, ReviewType } from '../schemas/review';
import { VetoService } from '../services/veto/veto.service';
import { ReviewsService } from '../services/reviews/reviews.service';
import { ReviewSearchOptions } from '../schemas/reviewSearchOptions';
import { ReviewsDomUtilsService } from '../services/reviews/reviews.dom-utils.service';

@Component({
  selector: 'app-review-list-row',
  templateUrl: './review-list-row.component.html',
  styleUrls: ['./review-list-row.component.scss']
})
export class ReviewListRowComponent {

  private _review: Review;
  private _error: Error;
  private _success = false;
  private _expandAttachments = false;
  public reviewSearchOptions = ReviewSearchOptions;
  public loadedImages = [];

  public constructor(
    private vetoService: VetoService,
    private modalService: ModalService,
    private reviewsService: ReviewsService,
    private reviewsDomUtilsService: ReviewsDomUtilsService
  ) {
    this.reviewsDomUtilsService = reviewsDomUtilsService;
  }

  @Input() set review(review) {
    this._review = review;
  }

  @Input() searchBy: ReviewSearchOptions;

  get review(): Review {
    return this._review;
  }

  get error(): Error {
    return this._error;
  }

  get success(): boolean {
    return this._success;
  }

  get expandAttachments(): boolean {
    return this._expandAttachments;
  }

  set expandAttachments(expand: boolean) {
    this._expandAttachments = expand;
  }

  get reviewType(): string | undefined {
    switch (this._review.type) {
      case ReviewType.PRODUCT_REVIEW:
        return 'Product Review';
      case ReviewType.SERVICE_REVIEW:
        return 'Service Review';
      default:
        return undefined;
    }
  }

  public async changeState(state): Promise<void> {
    this.clearAlerts();
    try {
      this._review.state = await this.vetoService.changeState(this._review, state);
      this._success = true;
    } catch (error) {
      this._error = error;
    }
  }

  public getAllowedStates(): ReviewState[] {
    return this.vetoService.allowedStateChanges(this._review.state);
  }

  public roundedRating(): number {
    return Math.round(this.review.rating);
  }

  private clearAlerts(): void {
    this._error = null;
    this._success = false;
  }

  openModal(imageSource: string): void {
    this.modalService.openImageModal(this._review.images.indexOf(imageSource), this._review.images);
  }

  onImageLoad(imageSource: string): void {
    if (!this.loadedImages.includes(imageSource)) {
      this.loadedImages.push(imageSource);
    }
  }

  toggleChevron(): void {
    this._expandAttachments = !this._expandAttachments;
  }

  async handleShopReplyDelete() {
    if (confirm('Are you 100% sure you want to delete this reply?')) {
      try {
        await this.reviewsService.deleteShopReply(this._review.id);
        delete this._review.reply;
      } catch (error) {
        this._error = error;
      }
    }
  }

  async handleImageDisallow(imageSource: string) {
    const fileName = imageSource.substring(imageSource.lastIndexOf('/') + 1);
    const split = fileName.split('.');
    split.pop();
    const attachmentId = split.join('.');
    if (!attachmentId.startsWith('atc-') || attachmentId.length !== 40) {
      throw new Error('Sorry, attachmentId seems to be wrong. Please try again.');
    }
    if (confirm('Are you 100% sure you want to disallow this image?')) {
      try {
        await this.reviewsService.disallowImage(this._review.id, attachmentId);
        this._review.images = this._review.images.filter(image => image !== imageSource);
        this.loadedImages = this.loadedImages.filter(image => image !== imageSource);
      } catch (error) {
        this._error = error;
      }
    }
  }

}
