import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IDeclaration } from '../../../../core/models/declaration.interface';
import { IDeclarationMessage } from '../../../../core/models/declarationMessage.interface';

@Component({
  selector: 'app-decision-info',
  templateUrl: './decision-info.component.html',
  styleUrls: ['../info-cards.component.scss']
})
export class DecisionInfoComponent {

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
  }

  private _messages: IDeclarationMessage[];
  public get messages(): IDeclarationMessage[] {
    return this._messages;
  }
  @Input() public set messages(v: IDeclarationMessage[]) {
    this._messages = v;
  }
  //#endregion

  constructor() { }

}
