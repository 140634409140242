import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IProtectionsRequestFilter } from '../../../core/models/protectionsRequestFilter.interface';
import { environment } from '../../../../../../environments/environment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { SearchFilterService } from '../../../core/services/search-filter.service';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ITSEmployee } from '../../../core/models/tsEmployee.interface';
import { UserService } from '../../../../../core/services/user.service';
import { IEmployee, IEmployeesList } from '../../../core/models/employees.interface';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: [
    './filter-form.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FilterFormComponent implements OnInit {
  //#region Private Fields
  private readonly _searchFilterService: SearchFilterService;
  private readonly _translateService: TranslateService;
  private readonly _userService: UserService;
  //#endregion

  //#region Properties
  private _applyFilters = new EventEmitter<IProtectionsRequestFilter>();
  @Output() public get applyFilters(): EventEmitter<IProtectionsRequestFilter> {
    return this._applyFilters;
  }

  private _csvDownload = new EventEmitter<void>();
  @Output() public get csvDownload(): EventEmitter<void> {
    return this._csvDownload;
  }

  private _switchPlaymode = new EventEmitter<boolean>();
  @Output() public get switchPlaymode(): EventEmitter<boolean> {
    return this._switchPlaymode;
  }

  private _protectionStates = [
    'ALL_PROTECTIONS',
    'ACTIVE_PROTECTIONS',
    'PROTECTION_WAITING_FOR_ACTION',
    'ENDED_PROTECTIONS',
    'NON_GOODWILL_DECISION',
    'GOODWILL_DECISION',
    'ALL_DECLARATIONS',
    'ANSWER_OVERDUE',
  ];
  public get protectionStates(): Array<string> {
    return this._protectionStates;
  }

  private _riskCarriers = [
    'ATRADIUS',
    'TCRE',
    'ATRADIUS_CH',
    'R_V',
    'TRUSTED_SHOPS'
  ];
  public get riskCarriers(): Array<string> {
    return this._riskCarriers;
  }

  private _protectionState = 'PROTECTION_WAITING_FOR_ACTION';
  public get protectionState(): string {
    return this._protectionState;
  }
  public set protectionState(v: string) {
    if (v === 'ALL_PROTECTIONS') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = '';
      delete this._filterModel.goodwillInd;
    } else if (v === 'ACTIVE_PROTECTIONS') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = 'DECLARED,SHOP_ANSWERED,CONSUMER_CONFIRM,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,' +
        'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,FEEDBACK_NEEDED_SHOP,WAITING_FOR_BANKACCOUNT_INFO';
      this._filterModel.combinedState = '';
      delete this._filterModel.goodwillInd;
    } else if (v === 'PROTECTION_WAITING_FOR_ACTION') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = 'TS_CHECK,DECISION_MADE';
      this._filterModel.combinedState = '';
      delete this._filterModel.goodwillInd;
    } else if (v === 'ENDED_PROTECTIONS') {
      this._filterModel.protectionStates = 'CLOSED';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = '';
      delete this._filterModel.goodwillInd;
    } else if (v === 'GOODWILL_DECISION') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = '';
      this._filterModel.goodwillInd = true;
    } else if (v === 'NON_GOODWILL_DECISION') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = '';
      this._filterModel.goodwillInd = false;
    } else if (v === 'ALL_DECLARATIONS') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = 'REPORTED';
      delete this._filterModel.goodwillInd;
    } else if (v === 'ANSWER_OVERDUE') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
      this._filterModel.combinedState = 'OVERDUE';
      delete this._filterModel.goodwillInd;
    }
    this._protectionState = v;
  }

  private _riskCarrier = 'RISK_CARRIER';
  public get riskCarrier(): string {
    return this._riskCarrier;
  }
  public set riskCarrier(v: string) {
    if (v === 'RISK_CARRIER') {
      this._filterModel.riskCarrier = '';
    } else {
      this._filterModel.riskCarrier = v;
    }
    this._riskCarrier = v;
  }

  private _filterModel: IProtectionsRequestFilter = {
    tsId: '',
    shopUrl: '',
    email: '',
    protectionNumber: '',
    protectionUuid: '',
    orderNumber: '',
    protectionStates: '',
    declarationStates: 'TS_CHECK',
    validFrom: 0,
    validTo: 0,
    searchEmployeeUuid: '',
    riskCarrier: ''
  };
  public get filterModel(): IProtectionsRequestFilter {
    return this._filterModel;
  }

  private _datePickerOptions: IAngularMyDpOptions = {
    closeSelectorOnDateSelect: true,
    closeSelectorOnDocumentClick: true,
  };
  public get datePickerOptions(): IAngularMyDpOptions {
    return this._datePickerOptions;
  }

  private _fromDateModel = '';
  public get fromDateModel(): string {
    return this._fromDateModel;
  }

  private _toDateModel = '';
  public get toDateModel(): string {
    return this._toDateModel;
  }

  private _employees: IEmployee[] = [];
  public get employees(): IEmployee[] {
    return this._employees;
  }

  private _showCSVLink = false;
  public get showCSVLink(): boolean {
    return this._showCSVLink;
  }

  private _usePlaymode = true;
  public get usePlaymode(): boolean {
    return this._usePlaymode;
  }
  public set usePlaymode(v: boolean) {
    this._usePlaymode = v;
  }

  private _isRiskUser = false;
  public get isRiskUser(): boolean {
    return this._isRiskUser;
  }

  locale = 'en';

  // tslint:disable-next-line:max-line-length
  emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  //#endregion

  constructor(searchFilterService: SearchFilterService, translateService: TranslateService, userService: UserService) {
    this._searchFilterService = searchFilterService;
    this._translateService = translateService;
    this._userService = userService;
  }

  ngOnInit() {
    if (this._searchFilterService.hasAutoFilter) {
      this._filterModel = this._searchFilterService.autoFilter;

      if (this._filterModel.validFrom) {
        this._fromDateModel = formatDate(this._filterModel.validFrom, 'shortDate', this._translateService.currentLang);
      }
      if (this._filterModel.validTo) {
        this._toDateModel = formatDate(this._filterModel.validTo, 'shortDate', this._translateService.currentLang);
      }

      if (this._filterModel.declarationStates === 'TS_CHECK,DECISION_MADE') {
        this._protectionState = 'PROTECTION_WAITING_FOR_ACTION';
      } else if (this._filterModel.protectionStates === 'CLOSED') {
        this._protectionState = 'ENDED_PROTECTIONS';
      } else if (this._filterModel.declarationStates === 'DECLARED,SHOP_ANSWERED,CONSUMER_CONFIRM,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,' +
        'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,FEEDBACK_NEEDED_SHOP,WAITING_FOR_BANKACCOUNT_INFO') {
        this._protectionState = 'ACTIVE_PROTECTIONS';
      } else if (this._filterModel.goodwillInd !== undefined && !this._filterModel.goodwillInd) {
        this._protectionState = 'NON_GOODWILL_DECISION';
        this._showCSVLink = true;
      } else if (this._filterModel.goodwillInd) {
        this._protectionState = 'GOODWILL_DECISION';
        this._showCSVLink = true;
      } else if (this._filterModel.combinedState === 'OVERDUE') {
        this._protectionState = 'ANSWER_OVERDUE';
      } else if (this._filterModel.combinedState === 'REPORTED') {
        this._protectionState = 'ALL_DECLARATIONS';
      } else {
        this._protectionState = 'ALL_PROTECTIONS';
      }

      if (!this._filterModel.riskCarrier || this._filterModel.riskCarrier === '') {
        this._riskCarrier = 'RISK_CARRIER';
      } else {
        this._riskCarrier = this._filterModel.riskCarrier;
      }

      if (sessionStorage.getItem('usePlaymode')) {
        this._usePlaymode = sessionStorage.getItem('usePlaymode') === 'true';
        this._switchPlaymode.emit(this._usePlaymode);
      }
    }

    this._userService.locale.subscribe(locale => {
      this.locale = locale;
    });

    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isRiskUser = roles.includes('ts_risk') || roles.includes('myts_ts_risk');
    });

    this._userService.employees.subscribe((employees: IEmployeesList) => {
      if (employees.serviceCore.length > 0) {
        for (const employee of employees.serviceCore) {
          if (employee.email === this._userService.email.getValue()) {
            employee.firstname = '';
            break;
          }
        }
        this._employees = employees.serviceCore;

        this._employees.sort((a, b) => {
          if (a.firstname < b.firstname) {
            return -1;
          } else if (a.firstname > b.firstname) {
            return  1;
          }
          return 0;
        });
      }

      if (employees.serviceTemp.length > 0) {
        const jointUsers = employees.serviceTemp.map(employee => employee.uuid);
        this._employees.unshift({
          firstname: 'Consumer Service',
          lastname: '',
          uuid: jointUsers.join()
        });
      }
    });
  }

  setFilters(): void {
    const filter = Object.assign({}, this._filterModel);
    for (const key in filter) {
     if (filter[key] === 0 || filter[key] === '') {
       delete filter[key];
     } else {
       if (typeof filter[key] === 'string') {
         filter[key] = filter[key].trim();
       }
     }
    }
    this.applyFilters.emit(filter);
    if (this._protectionState === 'NON_GOODWILL_DECISION' || this._protectionState === 'GOODWILL_DECISION') {
      this._showCSVLink = true;
    } else {
      this._showCSVLink = false;
    }
  }

  setDate(date: IMyDateModel, type: string) {
    if (date.singleDate.jsDate !== null) {
      this._filterModel[type] = date.singleDate.jsDate.getTime();
    } else {
      this._filterModel[type] = 0;
    }
  }

  downloadCSV() {
    this._csvDownload.emit();
  }

  playmodeSwitch() {
    this._usePlaymode = !this._usePlaymode;
    this._switchPlaymode.emit(this._usePlaymode);
  }
}
