import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './page-frame/full-layout/index';
import { ReviewRejectionComponent } from '../features/review-rejection/review-rejection.component';

export const routes: Routes = [
  {
    path: 'review-rejection',
    component: ReviewRejectionComponent
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: '../features/protections/core/protections.module#ProtectionsModule'
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
