import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  ReplaySubject } from 'rxjs';
import 'rxjs/add/operator/map';

@Pipe({
  name: 'dynamicDecimal'
})
export class DynamicDecimalPipe implements PipeTransform {
  //#region Private Fields
  private readonly _translateService: TranslateService;
  private readonly _numberPipe: DecimalPipe;
  //#endregion

  //#region Ctor
  public constructor(translateService: TranslateService) {
    this._translateService = translateService;
    this._numberPipe = new DecimalPipe(this._translateService.currentLang);
  }
  //#endregion

  //#region Public Methods
  public transform(value: any, digitsInfo?: string, locale?: string): Observable<string> {
    const subject = new ReplaySubject<string>();
    subject.next(this.parse(value, digitsInfo, locale || this._translateService.currentLang));

    this._translateService
      .onLangChange
      .map(x =>
        subject.next(this.parse(value, digitsInfo, locale || x.lang)));

    return subject;
  }
  //#endregion

  //#region Private Methods
  private parse(value: any, digitsInfo?: string, locale?: string): string {
    return this._numberPipe.transform(value, digitsInfo, locale);
  }

  //#endregion
}
