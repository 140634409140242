
import {map} from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  ReplaySubject } from 'rxjs';


@Pipe({
  name: 'dynamicCurrency'
})
export class DynamicCurrencyPipe implements PipeTransform {
  //#region Private Fields
  private readonly _translateService: TranslateService;
  private readonly _currencyPipe: CurrencyPipe;
  //#endregion

  //#region Ctor
  public constructor(translateService: TranslateService) {
    this._translateService = translateService;
    this._currencyPipe = new CurrencyPipe(this._translateService.currentLang);
  }
  //#endregion

  //#region Public Methods
  public transform(value: any,
                   currencyCode?: string,
                   display?: (boolean | 'symbol' | 'code' | 'symbol-narrow'),
                   digitsInfo?: string, locale?: string): Observable<string> {
    const subject = new ReplaySubject<string>();
    subject.next(this.parse(value, currencyCode, display, digitsInfo, this._translateService.currentLang));

    this._translateService
      .onLangChange.pipe(
      map(x =>
        subject.next(this.parse(value, currencyCode, display, digitsInfo, x.lang))));

    return subject;
  }
  //#endregion

  //#region Private Methods
  private parse(value: any,
                currencyCode?: string,
                display?: (boolean | 'symbol' | 'code' | 'symbol-narrow'),
                digitsInfo?: string,
                locale?: string): string {
    return this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
  }
  //#endregion
}
