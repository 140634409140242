import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { IBankAccountData } from '../../../core/models/bankAccountData.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ibanValidator } from '../../../core/validators/iban-validator.directive';
import { ICountry } from '../../../core/models/country.interface';
import { bankAccountCountries } from '../../configuration/bank-account-countries';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../../core/services/user.service';
import { IDeclaration } from '../../../core/models/declaration.interface';

@Component({
  selector: 'app-bank-account-details',
  templateUrl: './bank-account-details.component.html',
  styleUrls: ['./bank-account-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankAccountDetailsComponent implements OnInit {
  //#region Properties
  private readonly _declarationService: DeclarationService;
  private readonly _userService: UserService;
  //#endregion

  //#region Properties
  @ViewChild(ModalDirective) bankAccountModal: ModalDirective;

  private _declaration: IDeclaration;
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
  }
  public get declaration(): IDeclaration {
    return this._declaration;
  }

  private _bankAccountData: IBankAccountData;
  public get bankAccountData(): IBankAccountData {
    return this._bankAccountData;
  }

  private _resetBankAccountData: IBankAccountData;

  bankAccountForm = this.fb.group({
    iban: ['', ibanValidator()],
    // tslint:disable-next-line:max-line-length
    bic: ['', Validators.compose([Validators.pattern(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/),
      Validators.required])],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    street: ['', Validators.required],
    number: ['', Validators.required],
    zipCode: ['', Validators.required],
    town: ['', Validators.required],
    countryId: ['57']
  });

  private _countryList: Array<ICountry> = [];
  public get countryList(): Array<ICountry> {
    return this._countryList;
  }

  private _showForm = false;
  public get showForm(): boolean {
    return this._showForm;
  }
  public set showForm(v: boolean) {
    this._showForm = v;
  }

  private _dataSaved = false;
  public get dataSaved(): boolean {
    return this._dataSaved;
  }

  private _dataSaveError = false;
  public get dataSaveError(): boolean {
    return this._dataSaveError;
  }

  private _isServiceUser = false;
  public get isServiceUser(): boolean {
    return this._isServiceUser;
  }
  //#endregion

  constructor(declarationService: DeclarationService,
              private fb: FormBuilder,
              userService: UserService,
              private translateService: TranslateService) {
    this._declarationService = declarationService;
    this._userService = userService;
  }

  ngOnInit(): void {
    const countries = bankAccountCountries.countries;
    const iso3Array = Object.keys(countries);
    this.translateService.get(iso3Array).subscribe(res => {
      this._countryList = Object.keys(countries)
        .map(key => Object.assign(countries[key], res[key] ? {translatedName: res[key]} : {translatedName: ''}));
      this._countryList.sort((a, b) => (a.order < 0 || b.order < 0) ? a.order - b.order : a.translatedName.localeCompare(b.translatedName));
    });

    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isServiceUser = roles.includes('ts_service') || roles.includes('myts_ts_service');
    });
  }

  async fetchData() {
    this.bankAccountModal.show();
    try {
      this._bankAccountData = await this._declarationService.getBankAccount(this._declaration.declarationUuid);
      this._bankAccountData.available = !!this._bankAccountData.firstname;
      if (this._bankAccountData.available) {
        this._resetBankAccountData = Object.assign({}, this._bankAccountData) as IBankAccountData;
        this.bankAccountForm.setValue({
          iban: this._bankAccountData.iban,
          bic: this._bankAccountData.bic,
          firstname: this._bankAccountData.firstname,
          lastname: this._bankAccountData.lastname,
          street: this._bankAccountData.street,
          number: this._bankAccountData.number,
          zipCode: this._bankAccountData.zipCode,
          town: this._bankAccountData.town,
          countryId: this._bankAccountData.countryId
        });
      }
    } catch {
      this._bankAccountData = {
        available: false
      };
    }
  }

  showData() {
    this.bankAccountModal.show();
  }

  async saveData() {
    const tempData = Object.assign({}, this._bankAccountData);
    this._bankAccountData = undefined;
    this._showForm = false;
    try {
      await this._declarationService.setBankAccount(this._declaration.declarationUuid,
        this.bankAccountForm.value as IBankAccountData);
      this._bankAccountData = await this._declarationService.getBankAccount(this._declaration.declarationUuid);
      this._resetBankAccountData = Object.assign({}, this._bankAccountData) as IBankAccountData;
      this._bankAccountData.available = !!this._bankAccountData.firstname;
      this._dataSaved = true;
      setTimeout(() => this._dataSaved = false, 5000);
    } catch {
      this._bankAccountData = Object.assign({}, this._resetBankAccountData) as IBankAccountData;
      this.bankAccountForm.setValue({
        iban: this._bankAccountData.iban,
        bic: this._bankAccountData.bic,
        firstname: this._bankAccountData.firstname,
        lastname: this._bankAccountData.lastname,
        street: this._bankAccountData.street,
        number: this._bankAccountData.number,
        zipCode: this._bankAccountData.zipCode,
        town: this._bankAccountData.town,
        countryId: this._bankAccountData.countryId
      });
      this._dataSaveError = true;
      setTimeout(() => this._dataSaveError = false, 5000);
    }
  }

  cancel() {
    this._showForm = false;
    this._bankAccountData = Object.assign({}, this._resetBankAccountData) as IBankAccountData;
    this.bankAccountForm.setValue({
      iban: this._bankAccountData.iban,
      bic: this._bankAccountData.bic,
      firstname: this._bankAccountData.firstname,
      lastname: this._bankAccountData.lastname,
      street: this._bankAccountData.street,
      number: this._bankAccountData.number,
      zipCode: this._bankAccountData.zipCode,
      town: this._bankAccountData.town,
      countryId: this._bankAccountData.countryId
    });
  }
}
