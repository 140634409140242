import { Injectable } from '@angular/core';
import { Review, ReviewState } from '../../schemas/review';
import { RejectMutation } from './reject-mutation.service';
import { ApproveMutation } from './approve-mutation.service';
import { DiscardMutation } from './discard-mutation.service';

@Injectable()
export class VetoService {

  constructor(
    private reject: RejectMutation,
    private approve: ApproveMutation,
    private discard: DiscardMutation
  ) { }

  public async changeState(review: Review, state: ReviewState): Promise<ReviewState> {
    switch (state) {
      case ReviewState.REJECTED:
        return await this.reject.apply(review);
        break;
      case ReviewState.APPROVED:
        return await this.approve.apply(review);
        break;
      case ReviewState.DISCARDED:
       return await this.discard.apply(review);
        break;
    }
    /* TODO const stateChanges = [(ReviewStateType.REJECTED) : this.reject.change, ...];
            const changeState = stateChanges[state];
            return changeState(review);
    * */
    throw new Error(`Could not change review state to ${state}. Only REJECTED, APPROVED or DISCARDED are allowed`);
  }

  public allowedStateChanges(currentState: ReviewState): ReviewState[] {
    switch (currentState) {
      case ReviewState.APPROVED:
        return [ReviewState.REJECTED];
      case ReviewState.REJECTED:
        return [ReviewState.APPROVED, ReviewState.DISCARDED];
      case ReviewState.MODERATION:
        return [ReviewState.APPROVED, ReviewState.REJECTED];
      case ReviewState.SUBMITTED:
        return [ReviewState.APPROVED, ReviewState.DISCARDED];
      default:
        return [];
    }
  }
}

