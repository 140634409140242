export const countries = {
  'countries': {
    'ABW': {
      'id': 1,
      'order': 1,
      'iso2': 'AW',
      'iso3': 'ABW'
    },
    'AFG': {
      'id': 2,
      'order': 2,
      'iso2': 'AF',
      'iso3': 'AFG'
    },
    'AGO': {
      'id': 3,
      'order': 3,
      'iso2': 'AO',
      'iso3': 'AGO'
    },
    'AIA': {
      'id': 4,
      'order': 4,
      'iso2': 'AI',
      'iso3': 'AIA'
    },
    'ALB': {
      'id': 5,
      'order': 5,
      'iso2': 'AL',
      'iso3': 'ALB'
    },
    'AND': {
      'id': 6,
      'order': 6,
      'iso2': 'AD',
      'iso3': 'AND'
    },
    'ANT': {
      'id': 7,
      'order': 7,
      'iso2': 'AN',
      'iso3': 'ANT'
    },
    'ARE': {
      'id': 8,
      'order': 8,
      'iso2': 'AE',
      'iso3': 'ARE'
    },
    'ARG': {
      'id': 9,
      'order': 9,
      'iso2': 'AR',
      'iso3': 'ARG'
    },
    'ARM': {
      'id': 10,
      'order': 10,
      'iso2': 'AM',
      'iso3': 'ARM'
    },
    'ASM': {
      'id': 11,
      'order': 11,
      'iso2': 'AS',
      'iso3': 'ASM'
    },
    'ATA': {
      'id': 12,
      'order': 12,
      'iso2': 'AQ',
      'iso3': 'ATA'
    },
    'ATF': {
      'id': 13,
      'order': 13,
      'iso2': 'TF',
      'iso3': 'ATF'
    },
    'ATG': {
      'id': 14,
      'order': 14,
      'iso2': 'AG',
      'iso3': 'ATG'
    },
    'AUS': {
      'id': 15,
      'order': 15,
      'iso2': 'AU',
      'iso3': 'AUS'
    },
    'AUT': {
      'id': 16,
      'order': -9,
      'iso2': 'AT',
      'iso3': 'AUT'
    },
    'AZE': {
      'id': 17,
      'order': 17,
      'iso2': 'AZ',
      'iso3': 'AZE'
    },
    'BDI': {
      'id': 18,
      'order': 18,
      'iso2': 'BI',
      'iso3': 'BDI'
    },
    'BEL': {
      'id': 19,
      'order': -2,
      'iso2': 'BE',
      'iso3': 'BEL'
    },
    'BEN': {
      'id': 20,
      'order': 20,
      'iso2': 'BJ',
      'iso3': 'BEN'
    },
    'BFA': {
      'id': 21,
      'order': 21,
      'iso2': 'BF',
      'iso3': 'BFA'
    },
    'BGD': {
      'id': 22,
      'order': 22,
      'iso2': 'BD',
      'iso3': 'BGD'
    },
    'BGR': {
      'id': 23,
      'order': 23,
      'iso2': 'BG',
      'iso3': 'BGR'
    },
    'BHR': {
      'id': 24,
      'order': 24,
      'iso2': 'BH',
      'iso3': 'BHR'
    },
    'BHS': {
      'id': 25,
      'order': 25,
      'iso2': 'BS',
      'iso3': 'BHS'
    },
    'BIH': {
      'id': 26,
      'order': 26,
      'iso2': 'BA',
      'iso3': 'BIH'
    },
    'BLR': {
      'id': 27,
      'order': 27,
      'iso2': 'BY',
      'iso3': 'BLR'
    },
    'BLZ': {
      'id': 28,
      'order': 28,
      'iso2': 'BZ',
      'iso3': 'BLZ'
    },
    'BMU': {
      'id': 29,
      'order': 29,
      'iso2': 'BM',
      'iso3': 'BMU'
    },
    'BOL': {
      'id': 30,
      'order': 30,
      'iso2': 'BO',
      'iso3': 'BOL'
    },
    'BRA': {
      'id': 31,
      'order': 31,
      'iso2': 'BR',
      'iso3': 'BRA'
    },
    'BRB': {
      'id': 32,
      'order': 32,
      'iso2': 'BB',
      'iso3': 'BRB'
    },
    'BRN': {
      'id': 33,
      'order': 33,
      'iso2': 'BN',
      'iso3': 'BRN'
    },
    'BTN': {
      'id': 34,
      'order': 34,
      'iso2': 'BT',
      'iso3': 'BTN'
    },
    'BVT': {
      'id': 35,
      'order': 35,
      'iso2': 'BV',
      'iso3': 'BVT'
    },
    'BWA': {
      'id': 36,
      'order': 36,
      'iso2': 'BW',
      'iso3': 'BWA'
    },
    'CAF': {
      'id': 37,
      'order': 37,
      'iso2': 'CF',
      'iso3': 'CAF'
    },
    'CAN': {
      'id': 38,
      'order': 38,
      'iso2': 'CA',
      'iso3': 'CAN'
    },
    'CCK': {
      'id': 39,
      'order': 39,
      'iso2': 'CC',
      'iso3': 'CCK'
    },
    'CHE': {
      'id': 40,
      'order': -8,
      'iso2': 'CH',
      'iso3': 'CHE'
    },
    'CHL': {
      'id': 41,
      'order': 41,
      'iso2': 'CL',
      'iso3': 'CHL'
    },
    'CHN': {
      'id': 42,
      'order': 42,
      'iso2': 'CN',
      'iso3': 'CHN'
    },
    'CIV': {
      'id': 43,
      'order': 43,
      'iso2': 'CI',
      'iso3': 'CIV'
    },
    'CMR': {
      'id': 44,
      'order': 44,
      'iso2': 'CM',
      'iso3': 'CMR'
    },
    'COD': {
      'id': 45,
      'order': 45,
      'iso2': 'CD',
      'iso3': 'COD'
    },
    'COG': {
      'id': 46,
      'order': 46,
      'iso2': 'CG',
      'iso3': 'COG'
    },
    'COK': {
      'id': 47,
      'order': 47,
      'iso2': 'CK',
      'iso3': 'COK'
    },
    'COL': {
      'id': 48,
      'order': 48,
      'iso2': 'CO',
      'iso3': 'COL'
    },
    'COM': {
      'id': 49,
      'order': 49,
      'iso2': 'KM',
      'iso3': 'COM'
    },
    'CPV': {
      'id': 50,
      'order': 50,
      'iso2': 'CV',
      'iso3': 'CPV'
    },
    'CRI': {
      'id': 51,
      'order': 51,
      'iso2': 'CR',
      'iso3': 'CRI'
    },
    'CUB': {
      'id': 52,
      'order': 52,
      'iso2': 'CU',
      'iso3': 'CUB'
    },
    'CXR': {
      'id': 53,
      'order': 53,
      'iso2': 'CX',
      'iso3': 'CXR'
    },
    'CYM': {
      'id': 54,
      'order': 54,
      'iso2': 'KY',
      'iso3': 'CYM'
    },
    'CYP': {
      'id': 55,
      'order': 55,
      'iso2': 'CY',
      'iso3': 'CYP'
    },
    'CZE': {
      'id': 56,
      'order': 56,
      'iso2': 'CZ',
      'iso3': 'CZE'
    },
    'DEU': {
      'id': 57,
      'order': -10,
      'iso2': 'DE',
      'iso3': 'DEU'
    },
    'DJI': {
      'id': 58,
      'order': 58,
      'iso2': 'DJ',
      'iso3': 'DJI'
    },
    'DMA': {
      'id': 59,
      'order': 59,
      'iso2': 'DM',
      'iso3': 'DMA'
    },
    'DNK': {
      'id': 60,
      'order': 60,
      'iso2': 'DK',
      'iso3': 'DNK'
    },
    'DOM': {
      'id': 61,
      'order': 61,
      'iso2': 'DO',
      'iso3': 'DOM'
    },
    'DZA': {
      'id': 62,
      'order': 62,
      'iso2': 'DZ',
      'iso3': 'DZA'
    },
    'ECU': {
      'id': 63,
      'order': 63,
      'iso2': 'EC',
      'iso3': 'ECU'
    },
    'EGY': {
      'id': 64,
      'order': 64,
      'iso2': 'EG',
      'iso3': 'EGY'
    },
    'ERI': {
      'id': 65,
      'order': 65,
      'iso2': 'ER',
      'iso3': 'ERI'
    },
    'ESH': {
      'id': 66,
      'order': 66,
      'iso2': 'EH',
      'iso3': 'ESH'
    },
    'ESP': {
      'id': 67,
      'order': -4,
      'iso2': 'ES',
      'iso3': 'ESP'
    },
    'EST': {
      'id': 68,
      'order': 68,
      'iso2': 'EE',
      'iso3': 'EST'
    },
    'ETH': {
      'id': 69,
      'order': 69,
      'iso2': 'ET',
      'iso3': 'ETH'
    },
    'FIN': {
      'id': 70,
      'order': 70,
      'iso2': 'FI',
      'iso3': 'FIN'
    },
    'FJI': {
      'id': 71,
      'order': 71,
      'iso2': 'FJ',
      'iso3': 'FJI'
    },
    'FLK': {
      'id': 72,
      'order': 72,
      'iso2': 'FK',
      'iso3': 'FLK'
    },
    'FRA': {
      'id': 73,
      'order': -6,
      'iso2': 'FR',
      'iso3': 'FRA'
    },
    'FRO': {
      'id': 74,
      'order': 74,
      'iso2': 'FO',
      'iso3': 'FRO'
    },
    'FSM': {
      'id': 75,
      'order': 75,
      'iso2': 'FM',
      'iso3': 'FSM'
    },
    'GAB': {
      'id': 76,
      'order': 76,
      'iso2': 'GA',
      'iso3': 'GAB'
    },
    'GBR': {
      'id': 77,
      'order': -5,
      'iso2': 'GB',
      'iso3': 'GBR'
    },
    'GEO': {
      'id': 78,
      'order': 78,
      'iso2': 'GE',
      'iso3': 'GEO'
    },
    'GHA': {
      'id': 79,
      'order': 79,
      'iso2': 'GH',
      'iso3': 'GHA'
    },
    'GIB': {
      'id': 80,
      'order': 80,
      'iso2': 'GI',
      'iso3': 'GIB'
    },
    'GIN': {
      'id': 81,
      'order': 81,
      'iso2': 'GN',
      'iso3': 'GIN'
    },
    'GLP': {
      'id': 82,
      'order': 82,
      'iso2': 'GP',
      'iso3': 'GLP'
    },
    'GMB': {
      'id': 83,
      'order': 83,
      'iso2': 'GM',
      'iso3': 'GMB'
    },
    'GNB': {
      'id': 84,
      'order': 84,
      'iso2': 'GW',
      'iso3': 'GNB'
    },
    'GNQ': {
      'id': 85,
      'order': 85,
      'iso2': 'GQ',
      'iso3': 'GNQ'
    },
    'GRC': {
      'id': 86,
      'order': 86,
      'iso2': 'GR',
      'iso3': 'GRC'
    },
    'GRD': {
      'id': 87,
      'order': 87,
      'iso2': 'GD',
      'iso3': 'GRD'
    },
    'GRL': {
      'id': 88,
      'order': 88,
      'iso2': 'GL',
      'iso3': 'GRL'
    },
    'GTM': {
      'id': 89,
      'order': 89,
      'iso2': 'GT',
      'iso3': 'GTM'
    },
    'GUF': {
      'id': 90,
      'order': 90,
      'iso2': 'GF',
      'iso3': 'GUF'
    },
    'GUM': {
      'id': 91,
      'order': 91,
      'iso2': 'GU',
      'iso3': 'GUM'
    },
    'GUY': {
      'id': 92,
      'order': 92,
      'iso2': 'GY',
      'iso3': 'GUY'
    },
    'HKG': {
      'id': 93,
      'order': 93,
      'iso2': 'HK',
      'iso3': 'HKG'
    },
    'HMD': {
      'id': 94,
      'order': 94,
      'iso2': 'HM',
      'iso3': 'HMD'
    },
    'HND': {
      'id': 95,
      'order': 95,
      'iso2': 'HN',
      'iso3': 'HND'
    },
    'HRV': {
      'id': 96,
      'order': 96,
      'iso2': 'hr',
      'iso3': 'HRV'
    },
    'HTI': {
      'id': 97,
      'order': 97,
      'iso2': 'HT',
      'iso3': 'HTI'
    },
    'HUN': {
      'id': 98,
      'order': 98,
      'iso2': 'HU',
      'iso3': 'HUN'
    },
    'IDN': {
      'id': 99,
      'order': 99,
      'iso2': 'ID',
      'iso3': 'IDN'
    },
    'IND': {
      'id': 100,
      'order': 100,
      'iso2': 'IN',
      'iso3': 'IND'
    },
    'IOT': {
      'id': 101,
      'order': 101,
      'iso2': 'IO',
      'iso3': 'IOT'
    },
    'IRL': {
      'id': 102,
      'order': 102,
      'iso2': 'IE',
      'iso3': 'IRL'
    },
    'IRN': {
      'id': 103,
      'order': 103,
      'iso2': 'IR',
      'iso3': 'IRN'
    },
    'IRQ': {
      'id': 104,
      'order': 104,
      'iso2': 'IQ',
      'iso3': 'IRQ'
    },
    'ISL': {
      'id': 105,
      'order': 105,
      'iso2': 'IS',
      'iso3': 'ISL'
    },
    'ISR': {
      'id': 106,
      'order': 106,
      'iso2': 'IL',
      'iso3': 'ISR'
    },
    'ITA': {
      'id': 107,
      'order': -1,
      'iso2': 'IT',
      'iso3': 'ITA'
    },
    'JAM': {
      'id': 108,
      'order': 108,
      'iso2': 'JM',
      'iso3': 'JAM'
    },
    'JOR': {
      'id': 109,
      'order': 109,
      'iso2': 'JO',
      'iso3': 'JOR'
    },
    'JPN': {
      'id': 110,
      'order': 110,
      'iso2': 'JP',
      'iso3': 'JPN'
    },
    'KAZ': {
      'id': 111,
      'order': 111,
      'iso2': 'KZ',
      'iso3': 'KAZ'
    },
    'KEN': {
      'id': 112,
      'order': 112,
      'iso2': 'KE',
      'iso3': 'KEN'
    },
    'KGZ': {
      'id': 113,
      'order': 113,
      'iso2': 'KG',
      'iso3': 'KGZ'
    },
    'KHM': {
      'id': 114,
      'order': 114,
      'iso2': 'KH',
      'iso3': 'KHM'
    },
    'KIR': {
      'id': 115,
      'order': 115,
      'iso2': 'KI',
      'iso3': 'KIR'
    },
    'KNA': {
      'id': 116,
      'order': 116,
      'iso2': 'KN',
      'iso3': 'KNA'
    },
    'KOR': {
      'id': 117,
      'order': 117,
      'iso2': 'KR',
      'iso3': 'KOR'
    },
    'KWT': {
      'id': 118,
      'order': 118,
      'iso2': 'KW',
      'iso3': 'KWT'
    },
    'LAO': {
      'id': 119,
      'order': 119,
      'iso2': 'LA',
      'iso3': 'LAO'
    },
    'LBN': {
      'id': 120,
      'order': 120,
      'iso2': 'LB',
      'iso3': 'LBN'
    },
    'LBR': {
      'id': 121,
      'order': 121,
      'iso2': 'LR',
      'iso3': 'LBR'
    },
    'LBY': {
      'id': 122,
      'order': 122,
      'iso2': 'LY',
      'iso3': 'LBY'
    },
    'LCA': {
      'id': 123,
      'order': 123,
      'iso2': 'LC',
      'iso3': 'LCA'
    },
    'LIE': {
      'id': 124,
      'order': 124,
      'iso2': 'LI',
      'iso3': 'LIE'
    },
    'LKA': {
      'id': 125,
      'order': 125,
      'iso2': 'LK',
      'iso3': 'LKA'
    },
    'LSO': {
      'id': 126,
      'order': 126,
      'iso2': 'LS',
      'iso3': 'LSO'
    },
    'LTU': {
      'id': 127,
      'order': 127,
      'iso2': 'LT',
      'iso3': 'LTU'
    },
    'LUX': {
      'id': 128,
      'order': 128,
      'iso2': 'LU',
      'iso3': 'LUX'
    },
    'LVA': {
      'id': 129,
      'order': 129,
      'iso2': 'LV',
      'iso3': 'LVA'
    },
    'MAC': {
      'id': 130,
      'order': 130,
      'iso2': 'MO',
      'iso3': 'MAC'
    },
    'MAR': {
      'id': 131,
      'order': 131,
      'iso2': 'MA',
      'iso3': 'MAR'
    },
    'MCO': {
      'id': 132,
      'order': 132,
      'iso2': 'MC',
      'iso3': 'MCO'
    },
    'MDA': {
      'id': 133,
      'order': 133,
      'iso2': 'MD',
      'iso3': 'MDA'
    },
    'MDG': {
      'id': 134,
      'order': 134,
      'iso2': 'MG',
      'iso3': 'MDG'
    },
    'MDV': {
      'id': 135,
      'order': 135,
      'iso2': 'MV',
      'iso3': 'MDV'
    },
    'MEX': {
      'id': 136,
      'order': 136,
      'iso2': 'MX',
      'iso3': 'MEX'
    },
    'MHL': {
      'id': 137,
      'order': 137,
      'iso2': 'MH',
      'iso3': 'MHL'
    },
    'MKD': {
      'id': 138,
      'order': 138,
      'iso2': 'MK',
      'iso3': 'MKD'
    },
    'MLI': {
      'id': 139,
      'order': 139,
      'iso2': 'ML',
      'iso3': 'MLI'
    },
    'MLT': {
      'id': 140,
      'order': 140,
      'iso2': 'MT',
      'iso3': 'MLT'
    },
    'MMR': {
      'id': 141,
      'order': 141,
      'iso2': 'MM',
      'iso3': 'MMR'
    },
    'MNG': {
      'id': 142,
      'order': 142,
      'iso2': 'MN',
      'iso3': 'MNG'
    },
    'MNP': {
      'id': 143,
      'order': 143,
      'iso2': 'MP',
      'iso3': 'MNP'
    },
    'MOZ': {
      'id': 144,
      'order': 144,
      'iso2': 'MZ',
      'iso3': 'MOZ'
    },
    'MRT': {
      'id': 145,
      'order': 145,
      'iso2': 'MR',
      'iso3': 'MRT'
    },
    'MSR': {
      'id': 146,
      'order': 146,
      'iso2': 'MS',
      'iso3': 'MSR'
    },
    'MTQ': {
      'id': 147,
      'order': 147,
      'iso2': 'MQ',
      'iso3': 'MTQ'
    },
    'MUS': {
      'id': 148,
      'order': 148,
      'iso2': 'MU',
      'iso3': 'MUS'
    },
    'MWI': {
      'id': 149,
      'order': 149,
      'iso2': 'MW',
      'iso3': 'MWI'
    },
    'MYS': {
      'id': 150,
      'order': 150,
      'iso2': 'MY',
      'iso3': 'MYS'
    },
    'MYT': {
      'id': 151,
      'order': 151,
      'iso2': 'YT',
      'iso3': 'MYT'
    },
    'NAM': {
      'id': 152,
      'order': 152,
      'iso2': 'NA',
      'iso3': 'NAM'
    },
    'NCL': {
      'id': 153,
      'order': 153,
      'iso2': 'NC',
      'iso3': 'NCL'
    },
    'NER': {
      'id': 154,
      'order': 154,
      'iso2': 'NE',
      'iso3': 'NER'
    },
    'NFK': {
      'id': 155,
      'order': 155,
      'iso2': 'NF',
      'iso3': 'NFK'
    },
    'NGA': {
      'id': 156,
      'order': 156,
      'iso2': 'NG',
      'iso3': 'NGA'
    },
    'NIC': {
      'id': 157,
      'order': 157,
      'iso2': 'NI',
      'iso3': 'NIC'
    },
    'NIU': {
      'id': 158,
      'order': 158,
      'iso2': 'NU',
      'iso3': 'NIU'
    },
    'NLD': {
      'id': 159,
      'order': -7,
      'iso2': 'NL',
      'iso3': 'NLD'
    },
    'NOR': {
      'id': 160,
      'order': 160,
      'iso2': 'NO',
      'iso3': 'NOR'
    },
    'NPL': {
      'id': 161,
      'order': 161,
      'iso2': 'NP',
      'iso3': 'NPL'
    },
    'NRU': {
      'id': 162,
      'order': 162,
      'iso2': 'NR',
      'iso3': 'NRU'
    },
    'NZL': {
      'id': 163,
      'order': 163,
      'iso2': 'NZ',
      'iso3': 'NZL'
    },
    'OMN': {
      'id': 164,
      'order': 164,
      'iso2': 'OM',
      'iso3': 'OMN'
    },
    'PAK': {
      'id': 165,
      'order': 165,
      'iso2': 'PK',
      'iso3': 'PAK'
    },
    'PAN': {
      'id': 166,
      'order': 166,
      'iso2': 'PA',
      'iso3': 'PAN'
    },
    'PCN': {
      'id': 167,
      'order': 167,
      'iso2': 'PN',
      'iso3': 'PCN'
    },
    'PER': {
      'id': 168,
      'order': 168,
      'iso2': 'PE',
      'iso3': 'PER'
    },
    'PHL': {
      'id': 169,
      'order': 169,
      'iso2': 'PH',
      'iso3': 'PHL'
    },
    'PLW': {
      'id': 170,
      'order': 170,
      'iso2': 'PW',
      'iso3': 'PLW'
    },
    'PNG': {
      'id': 171,
      'order': 171,
      'iso2': 'PG',
      'iso3': 'PNG'
    },
    'POL': {
      'id': 172,
      'order': -3,
      'iso2': 'PL',
      'iso3': 'POL'
    },
    'PRI': {
      'id': 173,
      'order': 173,
      'iso2': 'PR',
      'iso3': 'PRI'
    },
    'PRK': {
      'id': 174,
      'order': 174,
      'iso2': 'KP',
      'iso3': 'PRK'
    },
    'PRT': {
      'id': 175,
      'order': 175,
      'iso2': 'PT',
      'iso3': 'PRT'
    },
    'PRY': {
      'id': 176,
      'order': 176,
      'iso2': 'PY',
      'iso3': 'PRY'
    },
    'PYF': {
      'id': 177,
      'order': 177,
      'iso2': 'PF',
      'iso3': 'PYF'
    },
    'QAT': {
      'id': 178,
      'order': 178,
      'iso2': 'QA',
      'iso3': 'QAT'
    },
    'REU': {
      'id': 179,
      'order': 179,
      'iso2': 'RE',
      'iso3': 'REU'
    },
    'ROU': {
      'id': 180,
      'order': 180,
      'iso2': 'RO',
      'iso3': 'ROU'
    },
    'RUS': {
      'id': 181,
      'order': 181,
      'iso2': 'RU',
      'iso3': 'RUS'
    },
    'RWA': {
      'id': 182,
      'order': 182,
      'iso2': 'RW',
      'iso3': 'RWA'
    },
    'SAU': {
      'id': 183,
      'order': 183,
      'iso2': 'SA',
      'iso3': 'SAU'
    },
    'SDN': {
      'id': 184,
      'order': 184,
      'iso2': 'SD',
      'iso3': 'SDN'
    },
    'SEN': {
      'id': 185,
      'order': 185,
      'iso2': 'SN',
      'iso3': 'SEN'
    },
    'SGP': {
      'id': 186,
      'order': 186,
      'iso2': 'SG',
      'iso3': 'SGP'
    },
    'SGS': {
      'id': 187,
      'order': 187,
      'iso2': 'GS',
      'iso3': 'SGS'
    },
    'SHN': {
      'id': 188,
      'order': 188,
      'iso2': 'SH',
      'iso3': 'SHN'
    },
    'SJM': {
      'id': 189,
      'order': 189,
      'iso2': 'SJ',
      'iso3': 'SJM'
    },
    'SLB': {
      'id': 190,
      'order': 190,
      'iso2': 'SB',
      'iso3': 'SLB'
    },
    'SLE': {
      'id': 191,
      'order': 191,
      'iso2': 'SL',
      'iso3': 'SLE'
    },
    'SLV': {
      'id': 192,
      'order': 192,
      'iso2': 'SV',
      'iso3': 'SLV'
    },
    'SMR': {
      'id': 193,
      'order': 193,
      'iso2': 'SM',
      'iso3': 'SMR'
    },
    'SOM': {
      'id': 194,
      'order': 194,
      'iso2': 'SO',
      'iso3': 'SOM'
    },
    'SPM': {
      'id': 195,
      'order': 195,
      'iso2': 'PM',
      'iso3': 'SPM'
    },
    'STP': {
      'id': 196,
      'order': 196,
      'iso2': 'ST',
      'iso3': 'STP'
    },
    'SUR': {
      'id': 197,
      'order': 197,
      'iso2': 'SR',
      'iso3': 'SUR'
    },
    'SVK': {
      'id': 198,
      'order': 198,
      'iso2': 'SK',
      'iso3': 'SVK'
    },
    'SVN': {
      'id': 199,
      'order': 199,
      'iso2': 'SI',
      'iso3': 'SVN'
    },
    'SWE': {
      'id': 200,
      'order': 200,
      'iso2': 'SE',
      'iso3': 'SWE'
    },
    'SWZ': {
      'id': 201,
      'order': 201,
      'iso2': 'SZ',
      'iso3': 'SWZ'
    },
    'SYC': {
      'id': 202,
      'order': 202,
      'iso2': 'SC',
      'iso3': 'SYC'
    },
    'SYR': {
      'id': 203,
      'order': 203,
      'iso2': 'SY',
      'iso3': 'SYR'
    },
    'TCA': {
      'id': 204,
      'order': 204,
      'iso2': 'TC',
      'iso3': 'TCA'
    },
    'TCD': {
      'id': 205,
      'order': 205,
      'iso2': 'TD',
      'iso3': 'TCD'
    },
    'TGO': {
      'id': 206,
      'order': 206,
      'iso2': 'TG',
      'iso3': 'TGO'
    },
    'THA': {
      'id': 207,
      'order': 207,
      'iso2': 'TH',
      'iso3': 'THA'
    },
    'TJK': {
      'id': 208,
      'order': 208,
      'iso2': 'TJ',
      'iso3': 'TJK'
    },
    'TKL': {
      'id': 209,
      'order': 209,
      'iso2': 'TK',
      'iso3': 'TKL'
    },
    'TKM': {
      'id': 210,
      'order': 210,
      'iso2': 'TM',
      'iso3': 'TKM'
    },
    'TMP': {
      'id': 211,
      'order': 211,
      'iso2': 'TP',
      'iso3': 'TMP'
    },
    'TON': {
      'id': 212,
      'order': 212,
      'iso2': 'TO',
      'iso3': 'TON'
    },
    'TTO': {
      'id': 213,
      'order': 213,
      'iso2': 'TT',
      'iso3': 'TTO'
    },
    'TUN': {
      'id': 214,
      'order': 214,
      'iso2': 'TN',
      'iso3': 'TUN'
    },
    'TUR': {
      'id': 215,
      'order': 215,
      'iso2': 'TR',
      'iso3': 'TUR'
    },
    'TUV': {
      'id': 216,
      'order': 216,
      'iso2': 'TV',
      'iso3': 'TUV'
    },
    'TWN': {
      'id': 217,
      'order': 217,
      'iso2': 'TW',
      'iso3': 'TWN'
    },
    'TZA': {
      'id': 218,
      'order': 218,
      'iso2': 'TZ',
      'iso3': 'TZA'
    },
    'UGA': {
      'id': 219,
      'order': 219,
      'iso2': 'UG',
      'iso3': 'UGA'
    },
    'UKR': {
      'id': 220,
      'order': 220,
      'iso2': 'UA',
      'iso3': 'UKR'
    },
    'URY': {
      'id': 222,
      'order': 222,
      'iso2': 'UY',
      'iso3': 'URY'
    },
    'USA': {
      'id': 223,
      'order': 223,
      'iso2': 'US',
      'iso3': 'USA'
    },
    'UZB': {
      'id': 224,
      'order': 224,
      'iso2': 'UZ',
      'iso3': 'UZB'
    },
    'VAT': {
      'id': 225,
      'order': 225,
      'iso2': 'VA',
      'iso3': 'VAT'
    },
    'VCT': {
      'id': 226,
      'order': 226,
      'iso2': 'VC',
      'iso3': 'VCT'
    },
    'VEN': {
      'id': 227,
      'order': 227,
      'iso2': 'VE',
      'iso3': 'VEN'
    },
    'VGB': {
      'id': 228,
      'order': 228,
      'iso2': 'VG',
      'iso3': 'VGB'
    },
    'VIR': {
      'id': 229,
      'order': 229,
      'iso2': 'VI',
      'iso3': 'VIR'
    },
    'VNM': {
      'id': 230,
      'order': 230,
      'iso2': 'VN',
      'iso3': 'VNM'
    },
    'VUT': {
      'id': 231,
      'order': 231,
      'iso2': 'VU',
      'iso3': 'VUT'
    },
    'WLF': {
      'id': 232,
      'order': 232,
      'iso2': 'WF',
      'iso3': 'WLF'
    },
    'WSM': {
      'id': 233,
      'order': 233,
      'iso2': 'WS',
      'iso3': 'WSM'
    },
    'YEM': {
      'id': 234,
      'order': 234,
      'iso2': 'YE',
      'iso3': 'YEM'
    },
    'YUG': {
      'id': 235,
      'order': 235,
      'iso2': 'YU',
      'iso3': 'YUG'
    },
    'ZAF': {
      'id': 236,
      'order': 236,
      'iso2': 'ZA',
      'iso3': 'ZAF'
    },
    'ZMB': {
      'id': 237,
      'order': 237,
      'iso2': 'ZM',
      'iso3': 'ZMB'
    },
    'ZWE': {
      'id': 238,
      'order': 238,
      'iso2': 'ZW',
      'iso3': 'ZWE'
    },
    'MNE': {
      'id': 239,
      'order': 239,
      'iso2': 'ME',
      'iso3': 'MNE'
    },
    'SRB': {
      'id': 240,
      'order': 240,
      'iso2': 'RS',
      'iso3': 'SRB'
    },
    'TLS': {
      'id': 241,
      'order': 241,
      'iso2': 'TL',
      'iso3': 'TLS'
    }

  }
};
