export enum ReviewSearchOptions {
    REVIEW_ID = 'REVIEW_ID',
    EMAIL = 'EMAIL',
    SUBMITTED = 'SUBMITTED',
}

export const ReviewSearchOptionToPlaceholderMapping: Record<ReviewSearchOptions, string> = {
    [ReviewSearchOptions.EMAIL]: 'email@email.com',
    [ReviewSearchOptions.REVIEW_ID]: 'Review ID',
    [ReviewSearchOptions.SUBMITTED]: '-',
};
