import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ChannelTsidMappingResponse } from '../../schemas/channelTsidMappingResponse';
import { ApiKeyService } from '../api-key/api-key.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ChannelService {

  public constructor(
    private apiKeyService: ApiKeyService,
    private http: HttpClient
  ) { }

  public async getChannel(tsid: string): Promise<string> {
    const url = `${environment.reviews.apiBaseUrl}/mapping/myts-etrusted/${tsid}`;
    const apiKey = await this.apiKeyService.channelMapperAPIKey();
    const options = {
      headers: new HttpHeaders({
        'x-api-key': apiKey
      })
    };

    try {
      return await this.http.get<ChannelTsidMappingResponse>(url, options)
        .pipe(
          map(data => data.channelRef)
        )
        .toPromise();

    } catch (error) {
      console.error(error.message);
      let message = 'Sorry it\'s not you it\'s me. Please try again.';
      message = this.errorHandling(error, message, tsid);

      throw new Error(message);
    }
  }

  private errorHandling(error, message, tsid: string) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        message = `No channel mapped to TSID: ${tsid}.`;
      }
      if (error.status === 403) {
        message = `API key is incorrect.`;
      }
    }
    return message;
  }
}
