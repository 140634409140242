import {NgModule} from '@angular/core';
import {ReviewRejectionComponent} from './review-rejection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';
import { ReviewListComponent } from './review-list/review-list.component';
import { ReviewListRowComponent } from './review-list-row/review-list-row.component';
import { ChannelService } from './services/channel/channel.service';
import { ReviewsService } from './services/reviews/reviews.service';
import { ApiKeyService } from './services/api-key/api-key.service';
import { VetoService } from './services/veto/veto.service';
import { ApproveMutation } from './services/veto/approve-mutation.service';
import { DiscardMutation } from './services/veto/discard-mutation.service';
import { RejectMutation } from './services/veto/reject-mutation.service';
import { HttpClientModule } from '@angular/common/http';
import { ShopReferenceService } from './services/shop-reference/shop-reference.service';
import { ImageModalGalleryComponent } from './image-modal-gallery/image-modal-gallery.component';
import { ModalService } from './services/modal/modal.service';
import { ImagePathPipe } from './pipes/imagePath.pipe';

@NgModule({
  declarations: [
    ReviewRejectionComponent,
    ReviewListComponent,
    ReviewListRowComponent,
    ImageModalGalleryComponent,
    ImagePathPipe
  ],
  exports: [
    ReviewRejectionComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    ChannelService,
    ReviewsService,
    ApiKeyService,
    VetoService,
    ModalService,
    ApproveMutation,
    DiscardMutation,
    RejectMutation,
    ShopReferenceService
  ]
})
export class ReviewRejectionModule {
}
