import { Component, Input } from '@angular/core';
import { IMessageStreamItem } from '../../../core/models/messageStreamItem.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DeclarationService } from '../../../core/services/declaration.service';
import { IDeclaration } from '../../../core/models/declaration.interface';

@Component({
  selector: 'app-declaration-message',
  templateUrl: './declaration-message.component.html',
  styleUrls: [
    './declaration-message.component.scss'
  ],
  animations: [
    trigger('parent', [
      transition(':enter', [])
    ]),
    trigger('open', [
      state('false', style({
        height: 0
      })),
      state('true', style({
        height: '*'
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ]),
    trigger('boldHeadline', [
      state('false', style({
        marginBottom: 0
      })),
      state('true', style({
        marginBottom: '15px'
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ])
  ]
})
export class DeclarationMessageComponent {
  private readonly _declarationService: DeclarationService;
  //#region Properties
  private _streamItem: IMessageStreamItem;
  public get streamItem(): IMessageStreamItem {
    return this._streamItem;
  }
  @Input() public set streamItem(v: IMessageStreamItem) {
    this._streamItem = v;
    this._messageOpen = v.initiallyOpen;
    this._isOpened = v.initiallyOpen;
    this._isDocumentMessage = v.message === 'DOCUMENT_MESSAGE';
  }

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
  }

  private _messageOpen: boolean;
  public get messageOpen(): boolean {
    return this._messageOpen;
  }
  public set messageOpen(v: boolean) {
    this._messageOpen = v;
  }

  private _isOpened: boolean;
  public get isOpened(): boolean {
    return this._isOpened;
  }

  private _isDocumentMessage: boolean;
  public get isDocumentMessage(): boolean {
    return this._isDocumentMessage;
  }

  private _showDummyShopForm = '';
  public get showDummyShopForm(): string {
    return this._showDummyShopForm;
  }
  @Input() public set showDummyShopForm(v: string) {
    this._showDummyShopForm = v;
  }

  refund = 0;
  //#endregion

  constructor(declarationService: DeclarationService) {
    this._declarationService = declarationService;
  }

  toggleView(event: AnimationEvent) {
    this._isOpened = event['toState'];
  }

  openItem() {
    if (!this._isOpened) {
      this._messageOpen = true;
    }
  }

  async deleteDocument(documentUuid: string) {
    if (await this._declarationService.deleteDocument(this._streamItem.declarationUuid, documentUuid)) {
      const index = this._streamItem.documents.findIndex(x => x.document.uuid === documentUuid);
      if (index > -1) {
        this._streamItem.documents.splice(index, 1);
      }
    }
  }

}
