export const environment = {
  UC7: true,
  production: false,
  employeeOAuth: '5',
  protections: {
    declarationAPI: 'https://cns-integr.trustedshops.com/cns-declaration-service/v1/employee-users/',
    ordersApi: 'https://cns-integr.trustedshops.com/cns-orders-service/v1/employee-users/'
  },
  reviews: {
    apiBaseUrl: 'https://internal.etrusted.koeln'
  },
  keycloak: {
    realm: 'ts-sso-INTEGR',
    clientId: 'ts-sso-cns',
    host: 'https://sso-integr.trustedshops.com/auth',
    protectedUrls: [
      /^http[s]?\:\/\/cns-integr\.trustedshops\.com\/.*$/
    ]
  },
  datePickerOptions: {
    dateFormat: 'dd.mm.yyyy',
    dayLabels: {su: 'So', mo: 'Mo', tu: 'Di', we: 'Mi', th: 'Do', fr: 'Fr', sa: 'Sa'},
    monthLabels: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mär',
      4: 'Apr',
      5: 'Mai',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Okt',
      11: 'Nov',
      12: 'Dez'
    },
    todayBtnTxt: 'Heute'
  },
  attachmentBaseUrl: 'https://attachments.etrusted.koeln'
};
