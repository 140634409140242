import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDeclaration } from '../../../core/models/declaration.interface';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-close-box',
  templateUrl: './close-box.component.html',
  styleUrls: ['./close-box.component.scss']
})
export class CloseBoxComponent {
  //#region Properties
  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
  }

  private _closeDeclaration = new EventEmitter<void>();
  @Output() public get closeDeclaration(): EventEmitter<void> {
    return this._closeDeclaration;
  }

  @ViewChild(ModalDirective) securityModal: ModalDirective;
  //#endregion

  clickCloseButton() {
    this.securityModal.show();
  }

  clickConfirm() {
    this._closeDeclaration.emit();
    this.securityModal.hide();
  }
}
