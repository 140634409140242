import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, language: string = 'de', currency?: string): string {

    const decimalLength = 2;
    let chunkDelimiter = '.';
    let decimalDelimiter = ',';
    const chunkLength = 3;

    if (language === 'en') {
      chunkDelimiter = ',';
      decimalDelimiter = '.';
    }

    const result = '\\d(?=(\\d{' + chunkLength + '})+\\D)';
    const num = value.toFixed(Math.max(0, decimalLength));

    let formattedNumber = num.replace('.', decimalDelimiter).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);

    if (currency && language === 'en') {
      formattedNumber = currency + ' ' + formattedNumber;
    } else if (currency && language !== 'en') {
      formattedNumber = formattedNumber + ' ' + currency;
    }

    return formattedNumber;
  }

}
