import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IProtectionInfo } from '../../../../core/models/protectionInfo.interface';
import { IDeclaration } from '../../../../core/models/declaration.interface';
import { UserService } from '../../../../../../core/services/user.service';
import { IQualityIndicators } from '../../../../core/models/qualityIndicators.interface';
import { DeclarationService } from '../../../../core/services/declaration.service';
import { saveAs } from 'file-saver';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-protections-info',
  templateUrl: './protections-info.component.html',
  styleUrls: ['./protections-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProtectionsInfoComponent implements OnInit, OnChanges {
  //#region Private fields
  private readonly _userService: UserService;
  private readonly _declarationService: DeclarationService;
  //#endregion

  //#region Properties
  private _infos: IProtectionInfo;
  public get infos(): IProtectionInfo {
    return this._infos;
  }
  @Input() public set infos(v: IProtectionInfo) {
    this._infos = v;
  }

  private _declarationDate: number;
  public get declarationDate(): number {
    return this._declarationDate;
  }

  private _createdOnDate: number;
  public get createdOnDate(): number {
    return this._createdOnDate;
  }

  private _assigneeUuid: string;
  public get assigneeUuid(): string {
    return this._assigneeUuid;
  }
  @Input() public set assigneeUuid(v: string) {
    this._assigneeUuid = v;
  }

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }
  @Input() public set declaration(v: IDeclaration) {
    this._declaration = v;
    this._declarationDate = new Date(this._declaration.creationDate).getTime();
    if (this._declaration.migrated) {
      this._createdOnDate = new Date(this._declaration.createdOn).getTime();
    }
    if (this._declaration.decision && environment.UC7) {
      this._provideXML = this._declaration.currency && (this._declaration.decision.goodwill || this._declaration.decision.reasonable);
    }
  }

  private _assignementNeeded = new EventEmitter<boolean>();
  @Output() public get assignementNeeded(): EventEmitter<boolean> {
    return this._assignementNeeded;
  }

  private _protectionDuration: number;
  public get protectionDuration(): number {
    return this._protectionDuration;
  }

  private _showPersonalData = false;
  public get showPersonalData(): boolean {
    return this._showPersonalData;
  }

  private _autoGuarantee = 'NOT_ACTIVE';
  public get autoGuarantee(): string {
    return this._autoGuarantee;
  }

  private _autoReview = 'NOT_ACTIVE';
  public get autoReview(): string {
    return this._autoReview;
  }

  private _shopperProfile = 'NOT_PUBLIC';
  public get shopperProfile(): string {
    return this._shopperProfile;
  }

  private _canAssign = false;
  public get canAssign(): boolean {
    return this._canAssign;
  }

  private _certificateState: string;
  public get certificateState(): string {
    return this._certificateState;
  }

  private _indicatorKey: string;
  public get indicatorKey(): string {
    return this._indicatorKey;
  }

  private _qualityIndicators: IQualityIndicators = {};
  public get qualityIndicators(): IQualityIndicators {
    return this._qualityIndicators;
  }

  private _provideXML = false;
  public get provideXML(): boolean {
    return this._provideXML;
  }

  private _isFinanceUser = false;
  public get isFinanceUser(): boolean {
    return this._isFinanceUser;
  }

  private _isServiceUser = false;
  public get isServiceUser(): boolean {
    return this._isServiceUser;
  }
  //#endregion

  constructor(public userService: UserService, public declarationService: DeclarationService) {
    this._userService = userService;
    this._declarationService = declarationService;
  }

  ngOnInit() {
    this._protectionDuration = Math.floor(
      (new Date(this._infos.protectionInfo.expirationDate).getTime() - new Date(this._infos.protectionInfo.orderDate).getTime()) /
      (1000 * 60 * 60 * 24));

    this._userService.employee_roles.subscribe((roles: string[]) => {
      this._isFinanceUser = roles.includes('ts_accounting') || roles.includes('myts_ts_accounting');
      this._isServiceUser = roles.includes('ts_service') || roles.includes('myts_ts_service');
    });

    this._infos.shopInfo.shop.qualityIndicators.forEach(indicator => {
      let refundIndicatorClass = 'red';
      if (indicator.refundIndicator >= 99) {
        refundIndicatorClass = 'green';
      } else if (indicator.refundIndicator < 99 && indicator.refundIndicator >= 95) {
        refundIndicatorClass = 'yellow';
      }

      let responseIndicatorClass = 'red';
      if (indicator.responseIndicator >= 99) {
        responseIndicatorClass = 'green';
      } else if (indicator.responseIndicator < 99 && indicator.responseIndicator >= 95) {
        responseIndicatorClass = 'yellow';
      }

      let reviewIndicatorClass = 'red';
      if (indicator.reviewIndicator >= 4) {
        reviewIndicatorClass = 'green';
      } else if (indicator.reviewIndicator < 4 && indicator.reviewIndicator >= 3) {
        reviewIndicatorClass = 'yellow';
      }

      Object.assign(this._qualityIndicators, this._qualityIndicators, {
        [indicator.monitoringInterval]: {
          refundIndicator: indicator.refundIndicator,
          refundIndicatorClass,
          responseIndicator: indicator.responseIndicator,
          responseIndicatorClass,
          reviewIndicator: indicator.reviewIndicator,
          reviewIndicatorClass,
          state: indicator.state
        }
      });
    });

    this._indicatorKey = Object.keys(this._qualityIndicators).includes('QI_90') ? 'QI_90' : Object.keys(this._qualityIndicators)[0];

    this._showPersonalData = !!this._infos.buyerInfo.lastName || !!this._infos.buyerInfo.street && !!this._infos.buyerInfo.city;

    const ppi = this._infos.buyerInfo.primeProductItems.map(x => x.primeProduct);
    this._autoGuarantee = ppi.indexOf('BUYER_AUTO_PROTECTION') > -1 ? 'ACTIVE' : 'NOT_ACTIVE';
    this._autoReview = ppi.indexOf('BUYER_AUTO_RATE_LATER') > -1 ? 'ACTIVE' : 'NOT_ACTIVE';

    this._shopperProfile = this._infos.buyerInfo.openProfileInd ? 'PUBLIC' : 'NOT_PUBLIC';

    if (this._infos.shopInfo.shop.certificateState === 'EMPTY') {
      this._certificateState = 'CERTIFICATE_VALID';
    } else if (this._infos.shopInfo.shop.certificateState === 'TEST' ||
      this._infos.shopInfo.shop.certificateState === 'INTEGRATION' ||
      this._infos.shopInfo.shop.certificateState === 'NO_AUDIT') {
      this._certificateState = 'NO_AUDIT';
    } else if (this._infos.shopInfo.shop.certificateState === 'CANCELED') {
      this._certificateState = 'CERTIFICATE_EXPIRED';
    } else {
      this._certificateState = 'CERTIFICATE_BLOCKED';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['assigneeUuid']) {
      this._canAssign = this._userService.employee_uuid.value !== this._assigneeUuid;
    }
  }

  assign() {
    this._canAssign = false;
    this._assignementNeeded.emit(false);
  }

  shiftIndicatorKey() {
    const index = Object.keys(this._qualityIndicators).indexOf(this._indicatorKey);
    this._indicatorKey = index >= Object.keys(this._qualityIndicators).length - 1 ?
      Object.keys(this._qualityIndicators)[0] : Object.keys(this._qualityIndicators)[index + 1];
  }

  async downloadXML() {
    const blob = await this._declarationService.getSepaXML(this._declaration.declarationUuid);
    saveAs(blob, `${this._infos.protectionInfo.protectionNumber}.xml`);
  }
}
