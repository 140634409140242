import { Injectable } from '@angular/core';
import { IProtectionsRequestFilter } from '../models/protectionsRequestFilter.interface';

@Injectable()
export class SearchFilterService {
  //#region Properties
  private _hasAutoFilter: boolean;
  public get hasAutoFilter(): boolean {
    return this._hasAutoFilter;
  }

  private _autoFilter: IProtectionsRequestFilter;
  public get autoFilter(): IProtectionsRequestFilter {
    return this._autoFilter;
  }

  private _hasPermanentFilter: boolean;
  public get hasPermanentFilter(): boolean {
    return this._hasPermanentFilter;
  }

  private _permanentFilter: IProtectionsRequestFilter;
  public get permanentFilter(): IProtectionsRequestFilter {
    return this._permanentFilter;
  }
  //#endregion

  constructor() {
    this._autoFilter = JSON.parse(sessionStorage.getItem('auto_filter')) as IProtectionsRequestFilter;
    this._hasAutoFilter = !!this._autoFilter;

    this._permanentFilter = JSON.parse(localStorage.getItem('filter')) as IProtectionsRequestFilter;
    this._hasPermanentFilter = !!this._permanentFilter;
  }

  setAutoFilter(filter: IProtectionsRequestFilter) {
    sessionStorage.setItem('auto_filter', JSON.stringify(filter));

    this._autoFilter = filter;
    this._hasAutoFilter = !!this._autoFilter;
  }

  setPermanentFilter(filter: IProtectionsRequestFilter) {
    localStorage.setItem('filter', JSON.stringify(filter));

    this._permanentFilter = filter;
    this._hasPermanentFilter = !!this._permanentFilter;
  }
}
