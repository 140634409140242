import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChannelService } from './services/channel/channel.service';
import { Review } from './schemas/review';
import { ReviewsService } from './services/reviews/reviews.service';
import { ApiKeyService } from './services/api-key/api-key.service';
import { ShopReferenceService } from './services/shop-reference/shop-reference.service';
import { ShopReferenceType } from './schemas/shop-reference-type';
import { ReviewSearchOptions, ReviewSearchOptionToPlaceholderMapping } from './schemas/reviewSearchOptions';
import { ReviewSearchFormService } from './services/shared/review-search-form.service';

@Component({
  selector: 'app-review-rejection',
  templateUrl: './review-rejection.component.html',
  styleUrls: ['./review-rejection.component.scss']
})
export class ReviewRejectionComponent implements OnInit {
  searchForm: FormGroup;
  formError = false;
  error: Error;
  searchValue: string;
  shopReference: string;
  shopReferenceType: ShopReferenceType;
  selectedSearchOption: ReviewSearchOptions;

  reviews: Review[];
  reviewSearchOptions = ReviewSearchOptions;
  ReviewSearchOptionToPlaceholderMapping = ReviewSearchOptionToPlaceholderMapping;
  shopReferenceValidators = [
    Validators.required,
    Validators.minLength(32)
  ];

  public constructor(
    private apiKey: ApiKeyService,
    private channelService: ChannelService,
    private reviewsService: ReviewsService,
    private shopReferenceService: ShopReferenceService,
    private reviewSearchFormService: ReviewSearchFormService
  ) {
  }

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      apiKey: new FormControl(
        '',
        [Validators.required]
      ),
      shopReference: new FormControl(
        '',
        this.shopReferenceValidators
      ),
      searchOption: new FormControl(
        ReviewSearchOptions.EMAIL,
        [Validators.required]
      ),
      searchValue: new FormControl(
        '',
        [
          Validators.required,
          Validators.email
        ]
      ),
    });

    this.searchForm.controls['shopReference'].valueChanges
      .subscribe((value: string) => {
        if (value) {
          this.shopReferenceType = this.shopReferenceService.getType(value);
        }
      });

    this.searchForm.controls['searchOption'].valueChanges
      .subscribe((value: ReviewSearchOptions) => {
        const shopReferenceControl = this.searchForm.get('shopReference');
        const searchValueControl = this.searchForm.get('searchValue');

        switch (value) {
          case ReviewSearchOptions.REVIEW_ID:
            shopReferenceControl.clearValidators();
            shopReferenceControl.setValue(null);
            shopReferenceControl.disable();

            searchValueControl.setValidators([Validators.required, Validators.minLength(32)]);
            searchValueControl.setValue(null);
            searchValueControl.enable();
            break;

          case ReviewSearchOptions.EMAIL:
            shopReferenceControl.setValidators(this.shopReferenceValidators);
            shopReferenceControl.enable();

            searchValueControl.setValidators([Validators.required, Validators.email]);
            searchValueControl.setValue(null);
            searchValueControl.enable();
            break;

          case ReviewSearchOptions.SUBMITTED:
            shopReferenceControl.setValidators(this.shopReferenceValidators);
            shopReferenceControl.enable();

            searchValueControl.clearValidators();
            searchValueControl.setValue(null);
            searchValueControl.disable();
            break;
        }

        shopReferenceControl.updateValueAndValidity();
        searchValueControl.updateValueAndValidity();
      });

    if (this.apiKey.get()) {
      this.searchForm.patchValue({apiKey: this.apiKey.get()});
    }

    this.reviewSearchFormService.searchTrigger$.subscribe(async () => {
      await this.onSearch();
    });
  }

  public async onSearch() {
    if (!this.searchForm.valid) {
      this.formError = true;
      return;
    }
    this.clearError();
    this.clearReviews();

    this.shopReference = this.searchForm.controls.shopReference.value;
    this.selectedSearchOption = this.searchForm.controls.searchOption.value;
    this.searchValue = this.searchForm.controls.searchValue.value || undefined;
    const apiKey = this.searchForm.controls.apiKey.value;

    this.apiKey.set(apiKey);

    try {
      let channelRef = this.shopReference;
      if (this.shopReferenceType === ShopReferenceType.TSID) {
        channelRef = await this.channelService.getChannel(this.shopReference);
      }
      this.reviews = await this.reviewsService.getReviews(channelRef, this.selectedSearchOption, this.searchValue);
    } catch (error) {
      this.error = error;
    }
  }

  private clearError(): void {
    this.error = null;
  }

  private clearReviews(): void {
    this.reviews = null;
  }
}
