import { Veto } from './veto';
import { Review, ReviewState } from '../../schemas/review';
import { Injectable } from '@angular/core';

@Injectable()

export class RejectMutation extends Veto {
  public async apply(review: Review): Promise<ReviewState> {
    if (![ReviewState.APPROVED, ReviewState.MODERATION].includes(review.state)) {
      throw new Error('Only review state APPROVED or MODERATION can be REJECTED');
    }

    return await this.changeReviewState(review.id, ReviewState.REJECTED);
  }
}
