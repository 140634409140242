import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../services/keycloak.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit {
  //#region Private fields
  private readonly _keycloakService: KeycloakService;
  private readonly _userService: UserService;
  //#endregion

  //#region Properties
  private _lang: string;
  public get lang(): string {
    return this._lang;
  }
  //#endregion

  constructor(keycloakService: KeycloakService, userService: UserService) {
    this._keycloakService = keycloakService;
    this._userService = userService;
  }

  ngOnInit() {
    this._userService.locale.subscribe(locale => this._lang = locale);
  }

  logout() {
    this._keycloakService.logout();
  }

  setLang(value: string) {
    if (value !== this._lang) {
      localStorage.setItem('locale', value);
      this._userService.locale.next(value);
    }
  }
}
